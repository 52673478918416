import React, { Fragment } from "react";
import { translate } from "ra-core";
import { Typography } from "@material-ui/core";

const IsAbandonedField = ({ translate, source, record }) => (
  <Fragment>
    <Typography variant="subheading">
      {translate(`resources.items.fields.${source}`)}
    </Typography>
    <Typography variant="body2">
      {record[source] ? translate(`app.yes`) : translate(`app.no`)}
    </Typography>
  </Fragment>
);
export default translate(IsAbandonedField);
