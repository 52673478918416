import {
  BooleanInput,
  DateInput,
  DateTimeInput,
  FormTab,
  LongTextInput,
  TextInput,
} from "react-admin";
import React, { Fragment } from "react";

import IsAbandonedField from "./fields/IsAbandonedField";
import LogsField from "./fields/LogsField";
import LotInput from "./inputs/LotInput";
import NewInventoryIdInput from "./inputs/NewInventoryIdInput";
import PicsInput from "./inputs/PicsInput";
import SuggestionableInput from "./inputs/SuggestionableInput";
import SuggestionableSelect from "./inputs/SuggestionableSelect";
import TypologyInput from "./inputs/TypologyInput";
import { WithPermissions } from "ra-core";
import isOffline from "../offline/isOffline";
import newLocations from "../i18n/newLocations";

const classes = {
  input: {
    marginRight: 16,
    width: 250,
    display: "block",
  },
  inputMedium: {
    maxWidth: 400,
  },
};
export default (create) =>
  [
    <FormTab label="general" key="general">
      <WithPermissions
        render={({ permissions }) => (
          <Fragment>
            <BooleanInput
              key="is_found"
              source="is_found"
              label="resources.items.fields.is_found"
            />
            <BooleanInput
              key="is_electromedical"
              source="is_electromedical"
              label="resources.items.fields.is_electromedical"
            />
            <BooleanInput
              key="is_checked_with_clinical_engineers"
              source="is_checked_with_clinical_engineers"
              label="resources.items.fields.is_checked_with_clinical_engineers"
            />
            <LotInput
              fullWidth
              style={classes.input}
              source="lot"
              key="lot"
              label="resources.items.fields.lot"
            />
            <TypologyInput
              fullWidth
              style={classes.input}
              source="typology"
              key="typology"
              label="resources.items.fields.typology"
            />
            {!create && (
              <TextInput
                fullWidth
                style={classes.input}
                key={"inventory_id"}
                source="inventory_id"
                label="resources.items.fields.inventory_id"
                disabled={!permissions || permissions.role !== "admin"}
              />
            )}
            {!create && (
              <TextInput
                fullWidth
                style={classes.input}
                key={"previous_inventory_id"}
                source="previous_inventory_id"
                label="resources.items.fields.previous_inventory_id"
                disabled={!permissions || permissions.role !== "admin"}
              />
            )}
            {!create && (
              <TextInput
                fullWidth
                style={classes.input}
                key={"previous_inventory_id_2"}
                source="previous_inventory_id_2"
                label="resources.items.fields.previous_inventory_id_2"
                disabled={!permissions || permissions.role !== "admin"}
              />
            )}
            <TextInput
              fullWidth
              style={classes.input}
              key={"id_number"}
              label="resources.items.fields.id_number"
              source="id_number"
            />
            {!create && (
              <TextInput
                fullWidth
                style={classes.input}
                key={"id_number_from_notes"}
                source="id_number_from_notes"
                label="resources.items.fields.id_number_from_notes"
                disabled={!permissions || permissions.role !== "admin"}
              />
            )}

            <NewInventoryIdInput
              fullWidth
              style={classes.input}
              key={"new_inventory_id"}
              source="new_inventory_id"
              label={"resources.items.fields.new_inventory_id"}
            />
            {permissions && permissions.role === "admin" && (
              <DateTimeInput
                fullWidth
                style={classes.inputMedium}
                label={"resources.items.fields.new_inventory_id_timestamp"}
                source="new_inventory_id_timestamp"
              />
            )}
            <DateInput
              fullWidth
              style={classes.input}
              key={"implementation_date"}
              label="resources.items.fields.implementation_date"
              source="implementation_date"
            />
            <LongTextInput
              fullWidth
              style={classes.input}
              key={"class"}
              label="resources.items.fields.class"
              source="class"
            />
          </Fragment>
        )}
      />
      {!create && (
        <LongTextInput
          key={"new_cost_center"}
          source="new_cost_center"
          disabled
        />
      )}
      <LongTextInput key={"name"} source="name" />
      <LongTextInput key={"brand"} source="brand" />
      <LongTextInput key={"model"} source="model" />
      {!create && <BooleanInput key={"is_abandoned"} source="is_abandoned" />}
      {!create && (
        <IsAbandonedField key={"is_abandoned_1"} source="is_abandoned_1" />
      )}
      <SuggestionableSelect
        key={"new_location"}
        fullWidth
        choices={newLocations.map((l) => ({
          id: l,
          name: l,
        }))}
        source="new_location"
      />
      <SuggestionableInput key={"floor"} source="floor" />
      {!isOffline() && <PicsInput source="pics" />}
      <LongTextInput key={"notes"} source="notes" />
    </FormTab>,
    !create && (
      <FormTab label="location" key="position">
        <LongTextInput key={"location"} source="location" disabled />

        <LongTextInput key={"district"} source="district" disabled />
        <LongTextInput key={"department"} source="department" disabled />
        <TextInput key={"building"} source="building" disabled />

        <TextInput key={"unit"} source="unit" disabled />
        <TextInput key={"location_ch4"} source="location_ch4" disabled />
      </FormTab>
    ),
    !create && (
      <FormTab label="details" key="details">
        <LongTextInput key={"cost_center"} source="cost_center" disabled />

        <LongTextInput
          key={"new_cost_center"}
          source="new_cost_center"
          disabled
        />

        <LongTextInput
          key={"responsibility_center"}
          source="responsibility_center"
          disabled
        />
        <LongTextInput key={"coordinator"} source="coordinator" disabled />
      </FormTab>
    ),
    !create && (
      <FormTab label="logs" key="logs">
        <LogsField source="changes" />
      </FormTab>
    ),
  ].filter((field) => field !== undefined);
