import { API_URL } from "./config";
import getHeaders from "./authHeaders";

export const GET_STATS = "GET_STATS";
export const IMPORT_CSV = "IMPORT_CSV";
const addItemsFeature = requestHandler => (type, resource, params) => {
  if (type === GET_STATS && resource === "items") {
    const uri = `${API_URL}/items/stats`;
    return fetch(uri, {
      method: "GET",
      headers: getHeaders()
    }).then(response => response.json());
  }
  if (type === IMPORT_CSV && resource === "items") {
    const url = `${API_URL}/items/import`;

    const headers = getHeaders();
    headers.delete("Content-Type");

    const data = new FormData();
    data.append("csv", params.csv);

    return fetch(url, {
      headers,
      method: "POST",
      body: data
    })
      .then(response => response.json())
      .then(response => ({ ...response }));
  }
  return requestHandler(type, resource, params);
};
export default addItemsFeature;
