import React from "react";
import compose from "recompose/compose";
import Card from "@material-ui/core/Card";
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { translate } from "react-admin";

import CardIcon from "./CardIcon";
import { Divider } from "@material-ui/core";

const styles = {
  main: {
    flex: "1",
    marginRight: "1em",
    marginTop: 20
  },
  card: {
    overflow: "inherit",
    textAlign: "right",
    padding: 16,
    minHeight: 52
  }
};

const Indicator = ({
  label,
  value,
  color,
  button,
  help,
  icon,
  translate,
  classes
}) => (
  <div className={classes.main}>
    <CardIcon Icon={icon} bgColor={color} />
    <Card className={classes.card}>
      <Typography className={classes.title} color="textSecondary">
        {translate(label)}
      </Typography>
      <Typography variant="subheading" component="h2">
        {value}
        {(button || help) && <Divider />}

        {help && (
          <Typography variant="caption" component="span">
            {translate(help)}
          </Typography>
        )}
        {button && <br />}
        {button}
      </Typography>
    </Card>
  </div>
);

const enhance = compose(withStyles(styles), translate);

export default enhance(Indicator);
