import React from "react";
import PictureInPictureIcon from "@material-ui/icons/Image";
import { Chip, Avatar } from "@material-ui/core";
import { translate } from "ra-core";
import { orange, green } from "@material-ui/core/colors";
const PicsField = ({ translate, source, record, ...props }) => (
  <Chip
    style={{
      backgroundColor: record[source] > 0 ? green[300] : orange[300]
    }}
    color="secondary"
    avatar={
      <Avatar
        style={{
          backgroundColor: record[source] > 0 ? green[300] : orange[300]
        }}
      >
        <PictureInPictureIcon
          style={{
            backgroundColor: record[source] > 0 ? green[300] : orange[300]
          }}
        />
      </Avatar>
    }
    label={record[source] || 0}
  />
);

export default translate(PicsField);
