import React from "react";
import { DateField } from "react-admin";
import { compose } from "recompose";
import { translate } from "ra-core";
import { Typography, withStyles } from "@material-ui/core";
import commonStyles from "./commonStyles";

const SmartDateField = ({ classes, translate, source, record, ...props }) =>
  record.id === 0 ? (
    <Typography variant="body2" className={classes.footerLabel}>
      {translate("resources.progress.fields.totals")}
    </Typography>
  ) : (
    <DateField source={source} record={record} {...props} />
  );
export default compose(translate, withStyles(commonStyles))(SmartDateField);
