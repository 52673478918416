import React from "react";
import { translate } from "react-admin";
import { Typography, withStyles, createStyles } from "@material-ui/core";

const styles = theme =>
  createStyles({
    root: {
      padding: theme.spacing.unit * 2,
      paddingTop: 0,
      paddingBottom: theme.spacing.unit * 1
    }
  });
const WarnAboutFilters = ({ classes, translate, filterValues }) =>
  filterValues && filterValues.user_id ? (
    <Typography variant="caption" component="p" className={classes.root}>
      {translate("resources.item-conflicts.messages.user_filter_warning")}
    </Typography>
  ) : null;

export default translate(withStyles(styles)(WarnAboutFilters));
