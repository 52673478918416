import PouchDB from "pouchdb";
import PouchDBFind from "pouchdb-find";
import PouchDBUpsert from "pouchdb-upsert";
import PouchDBUpsertBulk from "pouchdb-upsert-bulk";
// import PouchDBAdapterMemory from "pouchdb-adapter-memory";
// import PouchDBAdapterWebSQL from "pouchdb-adapter-websql";
PouchDB.plugin(PouchDBFind);
PouchDB.plugin(PouchDBUpsert);
PouchDB.plugin(PouchDBUpsertBulk);
// PouchDB.plugin(PouchDBAdapterMemory);
// PouchDB.plugin(PouchDBAdapterWebSQL);

const dbConfig = {
  auto_compaction: true,
  max_listeners: 20
};

export const createInventoryDatabase = () => new PouchDB("inventory", dbConfig);
export const createIndexesDatabase = () => new PouchDB("indexes", dbConfig);
export const createChangesDatabase = () => new PouchDB("changes", dbConfig);
export const createSettingsDatabase = () => new PouchDB("settings", dbConfig);
