import React from "react";
import { ReferenceInput, SelectInput, withTranslate } from "react-admin";

const LotSelect = withTranslate(({ choices, translate, ...props }) => {
  return (
    <SelectInput
      {...props}
      choices={choices.map((choice) => ({
        ...choice,
        name: translate(choice.name),
      }))}
    />
  );
});

const LotInput = (props) => (
  <ReferenceInput {...props} reference="items/lots" resource="items">
    <LotSelect />
  </ReferenceInput>
);
export default LotInput;
