import React from "react";
import { get } from "lodash";
import { NumberInput, FormDataConsumer } from "react-admin";

const NewInventoryIdInput = ({ disabled, ...props }) => (
  <FormDataConsumer {...props}>
    {({ formData, ...rest }) => (
      <NumberInput
        {...props}
        disabled={
          get(formData, "typology", "owned") === "service" ||
          (disabled && props.record && props.record.new_inventory_id > 0)
        }
        options={{
          inputProps: {
            min: 0,
            step: 1,
            pattern: "[0-9]*",
          },
        }}
      />
    )}
  </FormDataConsumer>
);
export default NewInventoryIdInput;
