import { WithPermissions, translate } from "react-admin";

import CustomAppBar from "./CustomAppBar";
import { Hidden } from "@material-ui/core";
import React from "react";
import Typography from "@material-ui/core/Typography";
import { compose } from "recompose";
import { withStyles } from "@material-ui/core/styles";

const styles = (theme) => ({
  title: {
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    overflow: "hidden",
    verticalAlgin: "bottom",
    fontWeight: "normal",
    marginRight: theme.spacing.unit,
    [theme.breakpoints.down("xs")]: {
      display: "none",
    },
  },
  subtitle: {
    flex: 1,
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    overflow: "hidden",
    verticalAlgin: "bottom",
    fontWeight: "normal",
  },
  spacer: {
    flex: 1,
  },
  logo: {
    marginRight: theme.spacing.unit,
  },
});

const MyAppBar = ({ translate, classes, ...props }) => {
  return (
    <CustomAppBar {...props}>
      <Typography className={classes.title} variant="title" color="inherit">
        Inventory
      </Typography>
      <Typography
        variant="subheading"
        id="react-admin-title"
        color="inherit"
        className={classes.subtitle}
      />
      <Hidden smDown>
        <WithPermissions
          render={({ permissions }) =>
            translate("app.welcome", {
              username: localStorage.getItem("username"),
            })
          }
        />
      </Hidden>
    </CustomAppBar>
  );
};
export default compose(withStyles(styles), translate)(MyAppBar);
