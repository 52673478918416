import { ProgressIcon } from "../icons";
import List from "./List";

export default {
  icon: ProgressIcon,
  options: {
    group: "dashboard",
    offline: false,
  },
  list: List,
};
