import React from "react";
import { compose } from "redux";
import { translate } from "ra-core";
import { Snackbar } from "@material-ui/core";
import SnackAlert from "../SnackAlert";
import isOffline from "../offline/isOffline";
const WarnBox = compose(translate)(({ translate, ...props }) => (
  <Snackbar
    anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
    open={isOffline() && Object.keys(props.filterValues).length === 0}
    autoHideDuration={10000}
  >
    <SnackAlert
      variant="warning"
      message={translate("resources.items.messages.no_filters")}
    />
  </Snackbar>
));
export default WarnBox;
