import React from "react";
import { Chip, Avatar } from "@material-ui/core";
import NewReleasesIcon from "@material-ui/icons/NewReleases";
import chooseColor from "../chooseColor";

const NewInventoryIdField = ({ record }) =>
  record.new_inventory_id !== null ? (
    <Chip
      avatar={
        <Avatar
          style={{
            backgroundColor: chooseColor(record)
          }}
        >
          <NewReleasesIcon
            style={{
              backgroundColor: chooseColor(record)
            }}
          />
        </Avatar>
      }
      label={record.new_inventory_id}
      style={{
        backgroundColor: chooseColor(record)
      }}
      component="span"
      color="secondary"
    />
  ) : (
    ""
  );
export default NewInventoryIdField;
