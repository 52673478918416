import React, { Fragment } from "react";

import { TextInput } from "react-admin";
import { Typography, Chip } from "@material-ui/core";
import { translate, REDUX_FORM_NAME } from "react-admin";
import { change } from "redux-form";

import { connect } from "react-redux";
import { compose } from "recompose";

const SuggestionableInput = ({ dispatch, translate, ...props }) => (
  <Fragment>
    <TextInput
      {...props}
      onChange={e => localStorage.setItem(props.source, e.target.value)}
      defaultValue={localStorage.getItem(props.source)}
    />
    {localStorage.getItem(props.source) && (
      <Fragment>
        <Typography variant="body1">
          {translate(`app.last_written_value`)}
          <br />
        </Typography>
        <Chip
          color="primary"
          component="a"
          label={localStorage.getItem(props.source)}
          style={{ cursor: "pointer" }}
          onClick={e =>
            !dispatch(
              change(
                REDUX_FORM_NAME,
                props.source,
                localStorage.getItem(props.source)
              )
            ) && !e.stopPropagation()
          }
        />
      </Fragment>
    )}
  </Fragment>
);

export default compose(
  translate,
  connect(() => ({}))
)(SuggestionableInput);
