import { API_URL } from "../config";
import getHeaders from "../authHeaders";
import createDescribedId from "./createDescribedId";

const preload = ({
  inventoryDb,
  settingsDb,
  indexesDb,
  resource = "items",
  count = 0,
  page = 1,
  pageCount = page,
  limit = 1000,
  lastSync = undefined,
  callback = undefined,
  sync
}) => {
  if (page > pageCount) {
    callback({ message: "Compacting the database... " });
    return Promise.resolve({ data: { message: null, count, pageCount } });
  }

  const url = `${API_URL}/${resource}?page=${page}&limit=${limit}&sync=${lastSync ||
    ""}`;
  const opts = { method: "GET", headers: getHeaders() };
  return fetch(url, opts)
    .then(response => response.json())
    .then(json => {
      const { data, pagination } = json;
      if (pagination.count === 0) {
        callback({ message: "Returning 0" });
        return settingsDb
          .upsert(sync.id, () => ({ ...sync, page: 1, timestamp: "" }))
          .then(() => ({ data: { count: 0, pageCount: 0 } }));
      }
      // Prima di caricare effettivamente i dati, devo generare una mappa delle chiavi locali
      // corrispondendi ad ognuna delle chiavi remote: ricora che hai generato delle chiavi
      // locali complesse per ottimizzare la ricerca usando PouchDB con indexOf direttamente
      // sul campo _id.
      return indexesDb
        .upsertBulk(
          data.map(record => ({
            _id: `${record.id}`,
            described_id: createDescribedId({ record })
          })),
          { replace: true }
        )
        .then(() =>
          inventoryDb
            .upsertBulk(
              data.map(d => ({
                ...d,
                changes: [],
                _id: createDescribedId({ record: d })
              })),
              { replace: true }
            )
            .then(() => {
              const { page_count, count } = pagination;
              callback({
                percentage: (100 / page_count) * page,
                message: `${((100 / page_count) * page).toFixed(
                  2
                )}% (${page}/${page_count})`
              });

              return settingsDb
                .upsert(sync.id, () => ({
                  ...sync,
                  page: page + 1,
                  timestamp: ""
                }))
                .then(sync =>
                  preload({
                    inventoryDb,
                    settingsDb,
                    indexesDb,
                    resource,
                    count,
                    page: page + 1,
                    pageCount: page_count,
                    limit,
                    sync,
                    lastSync,
                    callback
                  })
                );
            })
        );
    });
};

export default preload;
