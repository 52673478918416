import { UPDATE, CREATE } from "ra-core";

// in addUploadFeature.js
/**
 * Convert a `File` object returned by the upload input into a base 64 string.
 * That's not the most optimized way to store images in production, but it's
 * enough to illustrate the idea of data provider decoration.
 */
const convertFileToBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file.rawFile);

    reader.onload = () => resolve(reader.result);
    reader.onerror = reject;
  });

const addUploadFeature = (requestHandler) => (type, resource, params) => {
  if (
    [UPDATE, CREATE].indexOf(type) !== -1 &&
    resource === "maintenance-requests"
  ) {
    if (params.data.attachments && params.data.attachments.length) {
      const formerAttachments = params.data.attachments.filter(
        (p) => !(p.rawFile instanceof File)
      );
      const newAttachments = params.data.attachments.filter(
        (p) => p.rawFile instanceof File
      );

      return Promise.all(newAttachments.map(convertFileToBase64))
        .then((base64Attachments) =>
          base64Attachments.map((attachment64, index) => ({
            data: attachment64,
            filename: newAttachments[index].filename,
            filepath: newAttachments[index].filepath,
          }))
        )
        .then((transformedNewAttachments) =>
          requestHandler(type, resource, {
            ...params,
            data: {
              ...params.data,
              attachments: [...transformedNewAttachments, ...formerAttachments],
            },
          })
        );
    }
  } else if ([UPDATE, CREATE].indexOf(type) !== -1 && resource === "items") {
    // notice that following condition can be true only when `<ImageInput source="pics" />` component has parameter `multiple={true}`
    // if parameter `multiple` is false, then data.pics is not an array, but single object
    if (params.data.pics && params.data.pics.length) {
      // only freshly dropped pics are instance of File
      const formerPictures = params.data.pics.filter(
        (p) => !(p.rawFile instanceof File)
      );
      const newPictures = params.data.pics.filter(
        (p) => p.rawFile instanceof File
      );

      return Promise.all(newPictures.map(convertFileToBase64))
        .then((base64Pictures) =>
          base64Pictures.map((picture64, index) => ({
            data: picture64,
            filename: newPictures[index].filename,
            filepath: newPictures[index].filepath,
          }))
        )
        .then((transformedNewPictures) =>
          requestHandler(type, resource, {
            ...params,
            data: {
              ...params.data,
              pics: [...transformedNewPictures, ...formerPictures],
            },
          })
        );
    }
  }
  // for other request types and resources, fall back to the default request handler
  return requestHandler(type, resource, params);
};

export default addUploadFeature;
