let appUrl = `//${document.location.host}/`;
let debug = false;
if (appUrl.endsWith(":3000/")) {
  appUrl = "//dev.local:8880/";
  debug = true;
}
export const APP_URL = appUrl;
export const API_URL = `${APP_URL}api`;
export const BUILD = "1.4.13";
export const DEBUG = debug;
