import { ItemConflictIcon } from "../icons";
import List from "./List";
import Show from "./Show";

export default {
  options: {
    group: "dashboard",
    offline: false,
  },
  icon: ItemConflictIcon,
  list: List,
  show: Show,
};
