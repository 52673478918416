import { stopSubmit } from "redux-form";
import { put, takeEvery } from "redux-saga/effects";
import { CRUD_UPDATE_FAILURE, CRUD_CREATE_FAILURE } from "ra-core";

const mapFieldErrors = (field, errors) => {
  const keys = Object.keys(errors);
  const messages = keys.filter(k => typeof errors[k] === "string");

  if (messages.length > 0) {
    return {
      [field]: messages.map(m => errors[m]).join("\n")
    };
  } else {
    const out = keys.reduce(
      (errorMap, key) => ({
        ...errorMap,
        ...mapFieldErrors(key, errors[key])
      }),
      {}
    );
    return {
      [field]: out
    };
  }
};

export default function* errorSagas() {
  yield takeEvery(CRUD_CREATE_FAILURE, crudCreateFailure);
  yield takeEvery(CRUD_UPDATE_FAILURE, crudCreateFailure);
}

export function* crudCreateFailure(action) {
  if (!action.payload) {
    return;
  }
  if (action.payload.data.code !== 422) {
    return;
  }
  var json = action.payload;
  if (!json.data) {
    return;
  }
  var errors = json.data.errors;
  var fields = Object.keys(errors);
  var validationErrors = fields.reduce((errorsMap, field) => {
    return {
      ...errorsMap,
      ...mapFieldErrors(field, errors[field])
    };
  }, {});
  // json structure looks like this:
  // {
  //     username: "This username is already taken",
  //     age: "Your age must be above 18 years old"
  // }
  yield put(stopSubmit("record-form", validationErrors));
}
