export default ({ inventoryDb, indexesDb, params }) =>
  // Accedo alla tabella degli indici sulla chiave primaria
  // e recupero il "described_id" di uno specifico record con id intero
  // e a quel punto accedo all'inventory per recuperarne le informazioni.
  indexesDb.get(params.id).then(doc =>
    inventoryDb
      .get(doc.described_id)
      .then(doc => ({
        data: doc
      }))
      .catch(error => ({
        data: {
          error
        }
      }))
  );
