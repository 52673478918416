import React from "react";
import { Toolbar as RaToolbar, SaveButton, withTranslate } from "react-admin";
import { compose } from "recompose";

const Toolbar = ({ translate, ...props }) => (
  <RaToolbar {...props}>
    <SaveButton
      label={translate("resources.maintenance-requests.buttons.send")}
    />
  </RaToolbar>
);

export default compose(withTranslate)(Toolbar);
