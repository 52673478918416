import React from "react";
import {
  Show as RaShow,
  SimpleShowLayout,
  TextField,
  withTranslate,
} from "react-admin";
import { Typography, Divider } from "@material-ui/core";
import { withStyles, createStyles } from "@material-ui/core/styles";
import { compose } from "recompose";

const styles = createStyles((theme) => ({
  input: {
    minWidth: 300,
    maxWidth: 300,
    display: "inline-block",
    float: "left",
    marginRight: 16,
  },
  textarea: {
    width: "100%",
    clear: "both",
    display: "block",
  },
}));
const Sanitizie = ({ children }) => children;
const Show = ({ classes, translate, ...props }) => (
  <RaShow {...props}>
    <SimpleShowLayout>
      <Sanitizie>
        <Typography variant="display2" color="textPrimary">
          {translate("resources.maintenance-requests.titles.sent")}
        </Typography>
        <Divider />
        <br />
        <Typography variant="display1" color="textSecondary">
          {translate("resources.maintenance-requests.messages.sent")}
        </Typography>
        <br />
      </Sanitizie>
      <TextField className={classes.input} source="device" />
      <TextField className={classes.input} source="brand" />
      <TextField className={classes.input} source="model" />
      <TextField className={classes.input} source="inventory_id" />
      <TextField className={classes.input} source="serial_number" />
      <TextField className={classes.input} source="notes" />
      <TextField className={classes.textarea} source="defect" />
      <TextField className={classes.input} source="district" />
      <TextField className={classes.input} source="department" />
      <TextField className={classes.input} source="floor" />
      <TextField className={classes.input} source="room" />
      <TextField className={classes.input} source="access_times" />
      <TextField className={classes.input} source="referent" />
      <TextField className={classes.input} source="phone" />
      <TextField className={classes.input} source="email" />
    </SimpleShowLayout>
  </RaShow>
);
export default compose(withStyles(styles), withTranslate)(Show);
