import React, { Fragment, useState } from "react";
import PropTypes from "prop-types";
import onlyUpdateForKeys from "recompose/onlyUpdateForKeys";
import MuiButton from "@material-ui/core/Button";
import { withStyles, createStyles } from "@material-ui/core/styles";
import ArchiveIcon from "@material-ui/icons/Archive";
import { connect } from "react-redux";

import compose from "recompose/compose";
import {
  Responsive,
  Button,
  translate,
  withDataProvider,
  showNotification
} from "react-admin";

import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { IMPORT_CSV } from "../addItemsFeature";
import Dropzone from "react-dropzone";
import { Typography } from "@material-ui/core";
import LinearProgress from "@material-ui/core/LinearProgress";
import { APP_URL } from "../config";

const styles = theme =>
  createStyles({
    floating: {
      color: theme.palette.getContrastText(theme.palette.primary.main),
      margin: 0,
      top: "auto",
      right: 20,
      bottom: 60,
      left: "auto",
      position: "fixed",
      zIndex: 1000
    },
    floatingLink: {
      color: "inherit"
    },
    loader: {},
    dropZone: {
      background: theme.palette.background.default,
      cursor: "pointer",
      padding: "1rem",
      textAlign: "center",
      color: theme.palette.text.hint
    }
  });

const ImportButton = ({
  dispatch,
  basePath = "explorer",
  dataProvider,
  filterValues,
  className,
  classes = {},
  translate,
  label = "resources.items.actions.import",
  resource = "explorer",
  currentSort,
  showNotification,
  ...rest
}) => {
  const [open, setOpen] = useState(false);
  const [file, setFile] = useState(null);
  const [load, setLoad] = useState(false);
  const openDialog = () => {
    setOpen(true);
    setFile(null);
    setLoad(false);
  };
  const closeDialg = () => setOpen(false);
  const uploadCsv = () => {
    setLoad(true);
    dataProvider(IMPORT_CSV, "items", {
      csv: file
    }).then(({ success, data: { message, download } }) => {
      showNotification(message, success ? "info" : "warning");
      closeDialg();
      if (success || (download && download !== null && download.length > 0)) {
        // Download the CSV File
        setTimeout(
          () => (document.location.href = `${APP_URL}/${download}`),
          1
        );
      }
    });
  };
  const onDrop = (_, files) => (files.length === 1 ? setFile(files[0]) : null);
  return (
    <Fragment>
      <Responsive
        small={null}
        medium={
          <Button
            className={className}
            label={label}
            {...rest}
            onClick={openDialog}
          >
            <ArchiveIcon />
          </Button>
        }
      />
      <Dialog open={open}>
        <DialogTitle>{translate("resources.items.titles.import")}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {translate("resources.items.messages.import")}
          </DialogContentText>
          <Dropzone
            onDrop={onDrop}
            className={classes.dropZone}
            disableClick={false}
            accept={"*.xlsx"}
            multiple={false}
          >
            <p>{translate("resources.items.messages.import_drop_zone")}</p>
            {file !== null && (
              <Typography variant="body2">{file.name}</Typography>
            )}
          </Dropzone>
          {load && <LinearProgress className={classes.loader} />}
          {load && <br />}
          {load && (
            <DialogContentText>
              {translate("resources.items.messages.import_message")}
            </DialogContentText>
          )}
        </DialogContent>
        <DialogActions>
          <MuiButton
            disabled={file === null || load === true}
            onClick={uploadCsv}
            color="primary"
          >
            {translate("resources.items.actions.import")}
          </MuiButton>
          <MuiButton
            onClick={closeDialg}
            color="secondary"
            disabled={load === true}
          >
            {translate("ra.action.cancel")}
          </MuiButton>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
};
ImportButton.propTypes = {
  basePath: PropTypes.string,
  className: PropTypes.string,
  classes: PropTypes.object,
  label: PropTypes.string,
  size: PropTypes.string,
  translate: PropTypes.func.isRequired,
  icon: PropTypes.element
};

const enhance = compose(
  translate,
  withDataProvider,
  connect(null, {
    showNotification
  }),
  onlyUpdateForKeys([
    "basePath",
    "label",
    "translate",
    "filterValues",
    "currentSort"
  ]),
  withStyles(styles)
);

export default enhance(ImportButton);
