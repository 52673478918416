import React from "react";
import { Typography, createStyles, withStyles } from "@material-ui/core";
import { compose } from "recompose";
import { translate } from "ra-core";

const styles = theme =>
  createStyles({
    root: {
      padding: theme.spacing.unit * 2
    }
  });

const RecordsCount = ({ translate, classes, total }) => (
  <Typography className={classes.root} variant="subheading">
    {translate(`resources.items.messages.records_found`, { total })}
  </Typography>
);

export default compose(withStyles(styles), translate)(RecordsCount);
