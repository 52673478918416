import {
  GET_LIST,
  GET_ONE,
  UPDATE,
  CREATE,
  DELETE,
  DELETE_MANY,
  HttpError
} from "ra-core";

import {
  createInventoryDatabase,
  createSettingsDatabase,
  createChangesDatabase,
  createIndexesDatabase
} from "./offline/createDatabases";
import getList from "./offline/getList";
import createOrUpdate from "./offline/createOrUpdate";
import configure from "./offline/configure";
import getInfo from "./offline/getInfo";
import getOne from "./offline/getOne";
import synchronize from "./offline/synchronize";
import isOffline from "./offline/isOffline";
import getPendingChanges from "./offline/getPendingChanges";
import clearPendingChanges from "./offline/clearPendingChanges";

export const PRELOAD = "PRELOAD";
export const REFRESH = "REFRESH";
export const GET_INFO = "GET_INFO";
export const GET_PENDING_CHANGES = "GET_PENDING_CHANGES";
export const CLEAR_PENDING_CHANGES = "CLEAR_PENDING_CHANGES";
export const SYNCHRONIZE = "SYNCHRONIZE";
let inventoryDb = createInventoryDatabase();
let indexesDb = createIndexesDatabase();
let changesDb = createChangesDatabase();
let settingsDb = createSettingsDatabase();

const addOfflineDataFeature = requestHandler => (type, resource, params) => {
  const { callback } = params || {
    callback: () => console.warn("Callback not implemented bro!")
  };
  if (type === GET_PENDING_CHANGES) {
    return getPendingChanges({ changesDb });
  } else if (type === PRELOAD) {
    return configure({
      inventoryDb,
      settingsDb,
      indexesDb,
      resource,
      callback
    });
  } else if (type === CLEAR_PENDING_CHANGES) {
    return clearPendingChanges({ changesDb });
  } else if (type === REFRESH) {
    return Promise.all([
      inventoryDb.destroy(),
      settingsDb.destroy(),
      indexesDb.destroy()
    ]).then(() => {
      inventoryDb = createInventoryDatabase();
      settingsDb = createSettingsDatabase();
      indexesDb = createIndexesDatabase();
      return configure({
        inventoryDb,
        settingsDb,
        indexesDb,
        resource,
        callback
      });
    });
  } else if (type === GET_INFO) {
    return getInfo({ changesDb, settingsDb });
  } else if (type === SYNCHRONIZE) {
    return synchronize({ inventoryDb, changesDb, resource, requestHandler });
  }
  const offline = isOffline();
  if (!offline) {
    if (type === CREATE || type === UPDATE) {
      // Se sto aggiornando i records provvedo comunque a tenere traccia
      // delle modifiche nella copia locale del database cosi da evitare
      // fastidiosi disallineamenti visivi dei dati (e rotture di palle)
      return requestHandler(type, resource, params).then(response =>
        createOrUpdate({
          resource,
          inventoryDb,
          changesDb,
          params,
          type,
          creationId: response.data.id
        }).then(() => response)
      );
    }
    return requestHandler(type, resource, params);
  } else if (type === GET_LIST) {
    return getList({ inventoryDb, params });
  } else if (type === GET_ONE) {
    return getOne({ inventoryDb, indexesDb, resource, params });
  } else if (type === UPDATE || type === CREATE) {
    return createOrUpdate({ resource, inventoryDb, changesDb, params, type });
  } else if (type === DELETE || type === DELETE_MANY) {
    throw new HttpError("app.delete_not_permitted");
  }
};

export default addOfflineDataFeature;
