import React from "react";
import { Responsive } from "react-admin";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import moment from "moment";
import { withStyles } from "@material-ui/core/styles";
import { compose } from "recompose";
import { translate } from "ra-core";
import { Divider, Typography } from "@material-ui/core";

const styles = theme => ({
  root: {
    width: "100%",
    marginTop: theme.spacing.unit * 3,
    overflowX: "auto"
  },
  table: {
    minWidth: 700
  }
});

const LogsField = ({ classes, translate, source, record }) => (
  <Responsive
    small={
      <List component="nav">
        {[record].concat((record && record[source]) || []).map((row, index) => (
          <ListItem key={row.id || index}>
            <ListItemText
              component="div"
              primary={
                (row.user && row.user.username) ||
                translate("resources.items.added")
              }
              secondary={
                <Typography component="div">
                  {moment(row.modified).format("DD/MM/YYYY HH:mm:ss")}
                  <Divider />
                  {["id_number", "new_inventory_id", "name", "floor", "notes"]
                    .filter(
                      field =>
                        row[field] &&
                        row[field] !== null &&
                        row[field].length > 0
                    )
                    .map(field => (
                      <Typography key={field} variant="body1" component="span">
                        {translate(`resources.items.fields.${field}`)}:{" "}
                        {row[field]}
                      </Typography>
                    ))}
                </Typography>
              }
            />
          </ListItem>
        ))}
      </List>
    }
    medium={
      <Table className={classes.table}>
        <TableHead>
          <TableRow>
            <TableCell>
              {translate("resources.items.fields.id_number")}
            </TableCell>
            <TableCell>
              {translate("resources.items.fields.new_inventory_id")}
            </TableCell>
            <TableCell>{translate("resources.items.fields.name")}</TableCell>
            <TableCell>{translate("resources.items.fields.floor")}</TableCell>
            <TableCell>{translate("resources.items.fields.notes")}</TableCell>
            <TableCell>{translate("resources.items.fields.user")}</TableCell>
            <TableCell>
              {translate("resources.items.fields.modified")}
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {[record]
            .concat((record && record[source]) || [])
            .map((row, index) => {
              return (
                <TableRow key={row.id || index}>
                  <TableCell scope="row">{row.id_number}</TableCell>
                  <TableCell>{row.new_inventory_id}</TableCell>
                  <TableCell>{row.name}</TableCell>
                  <TableCell>{row.floor}</TableCell>
                  <TableCell>{row.notes}</TableCell>
                  <TableCell>
                    {(row.user && row.user.username) || "NA"}
                  </TableCell>
                  <TableCell>
                    {moment(row.modified).format("DD/MM/YYYY HH:mm:ss")}
                  </TableCell>
                </TableRow>
              );
            })}
        </TableBody>
      </Table>
    }
  />
);
export default compose(withStyles(styles), translate)(LogsField);
