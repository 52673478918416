import { Avatar, Chip, Typography } from "@material-ui/core";
import {
  Datagrid,
  DateField,
  EditButton,
  List,
  Responsive,
  SimpleList,
  TextField,
} from "react-admin";
import React, { Fragment } from "react";
import { WithPermissions, translate } from "ra-core";

import Badge from "@material-ui/core/Badge";
import ChangesField from "./fields/ChangesField";
import Filters from "./Filters";
import InventoryIdField from "./fields/InventoryIdField";
import ListActions from "./ListActions";
import NewInventoryIdField from "./fields/NewInventoryIdField";
import Pagination from "./Pagination";
import PicsField from "./fields/PicsField";
import RecordsCount from "./RecordsCount";
import UserField from "./fields/UserField";
import WarnAboutFilters from "./WarnAboutFilters";
import WarnBox from "./WarnBox";
import WarningIcon from "@material-ui/icons/Warning";
import chooseColor from "./chooseColor";
import { connect } from "react-redux";
import exporter from "./exporter";
import isOffline from "../offline/isOffline";

const MyDatagrid = connect((state) => ({
  isSidebarOpen: state.admin.ui.sidebarOpen,
}))(({ dispatch, isSidebarOpen, classes, large, ...props }) => {
  return (
    <Fragment>
      <WarnBox {...props} />
      <RecordsCount {...props} />
      <WarnAboutFilters {...props} />
      <div
        style={{
          maxWidth: `calc(100vw - ${getWidthToSubtract(
            isSidebarOpen ? 300 : 108
          )}px)`,
          overflowX: "auto",
        }}
      >
        <Datagrid
          {...props}
          rowStyle={(record) => ({
            borderLeft: chooseColor(record),
            borderLeftSize: 4,
            borderLeftStyle: "solid",
          })}
        >
          <InventoryIdField source="inventory_id" sortable={!isOffline()} />
          <NewInventoryIdField
            source="new_inventory_id"
            sortable={!isOffline()}
          />
          <ChangesField source="changes" sortable={false} />
          <PicsField source="pics_count" sortable={false} />
          <TextField source="name" sortable={!isOffline()} />
          <TextField source="typology" sortable={!isOffline()} />
          <TextField source="new_location" sortable={!isOffline()} />
          <TextField source="new_cost_center" sortable={!isOffline()} />
          <UserField label="resources.users.fields.username" />
          <DateField source="modified" showTime sortable={!isOffline()} />
          <EditButton />
        </Datagrid>
      </div>
    </Fragment>
  );
});
const MySimpleList = translate(({ translate, ...props }) => (
  <Fragment>
    <WarnBox {...props} />
    <RecordsCount {...props} />
    <SimpleList
      {...props}
      linkType="edit"
      pagination={<Pagination />}
      primaryText={(record) => (
        <Typography
          variant="title"
          style={{
            borderLeft: chooseColor(record),
            borderLeftSize: 4,
            borderLeftStyle: "solid",
            paddingLeft: 4,
          }}
        >
          {record.name}
        </Typography>
      )}
      secondaryText={(record) => (
        <Typography
          component="div"
          style={{
            borderLeft: chooseColor(record),
            borderLeftSize: 4,
            borderLeftStyle: "solid",
            paddingLeft: 4,
          }}
        >
          <Typography variant="body1" component="span">
            {record.new_location}
            <br />
            {record.new_cost_center}
          </Typography>
          {record.user && (
            <Typography variant="caption" component="span">
              {record.user.username}
            </Typography>
          )}
          <NewInventoryIdField record={record} />
          {(record.is_abandoned || record.is_abandoned_1) && (
            <Chip
              color="secondary"
              avatar={
                <Avatar style={{ backgroundColor: chooseColor(record) }}>
                  <WarningIcon
                    style={{ backgroundColor: chooseColor(record) }}
                  />
                </Avatar>
              }
              style={{
                backgroundColor: chooseColor(record),
                marginLeft: 10,
              }}
              label={translate("app.abandoned")}
            />
          )}
        </Typography>
      )}
      tertiaryText={(record) =>
        record.changes && record.changes.length > 0 ? (
          <Badge badgeContent={record.changes.length} color="primary">
            <Chip
              label={record.inventory_id > 0 ? record.inventory_id : "ND"}
            />
          </Badge>
        ) : (
          <Chip label={record.inventory_id > 0 ? record.inventory_id : "ND"} />
        )
      }
    />
  </Fragment>
));
const getWidthToSubtract = (w) => {
  return w + (window.innerWidth - document.documentElement.clientWidth);
};
const ItemList = ({ translate, ...props }) => (
  <WithPermissions
    render={({ permissions }) => {
      const additionalProps = {};
      if (!permissions || !permissions.role || permissions.role !== "admin") {
        additionalProps.bulkActionButtons = null;
      }
      return (
        <List
          {...props}
          {...additionalProps}
          perPage={20}
          actions={<ListActions />}
          sort={{ field: "modified", order: "DESC" }}
          exporter={exporter.bind(this, translate)}
          filters={<Filters />}
        >
          <Responsive
            small={<MySimpleList />}
            medium={<MyDatagrid />}
            large={<MyDatagrid large />}
          />
        </List>
      );
    }}
  />
);
export default translate(ItemList);
