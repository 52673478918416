import React, { Fragment } from "react";

import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import EnhancedTableHead from "./EnhancedTableHead";
import headers from "./headers";
import { Typography, TextField, Grid } from "@material-ui/core";
import chooseColor from "../../items/chooseColor";
import { translate } from "ra-core";
import moment from "moment";

function desc(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getSorting(order, orderBy) {
  return order === "desc"
    ? (a, b) => desc(a, b, orderBy)
    : (a, b) => -desc(a, b, orderBy);
}

const styles = theme => ({
  root: {
    width: "auto",
    marginTop: theme.spacing.unit * 3
  },
  table: {
    minWidth: 1020
  },
  tableWrapper: {
    overflowX: "auto"
  }
});

class EnhancedTable extends React.Component {
  state = {
    order: "asc",
    orderBy: "name",
    keyword: null,
    selected: [],
    headers: [],
    data: [],
    page: 0,
    rowsPerPage: 5
  };

  constructor(props) {
    super(props);

    this.state.data = props.data || [];
    this.state.headers = headers.reduce(
      (map, header) => ({
        ...map,
        [props.translate(
          `resources.items.fields.${header.label || header.id}`
        )]: header.id
      }),
      {}
    );
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.data !== this.state.data && nextProps.data !== undefined) {
      this.setState({
        data: nextProps.data.map(row => ({
          ...row,
          modified: moment(row.modified)
        }))
      });
    }
  }

  handleRequestSort = (event, property) => {
    const orderBy = property;
    let order = "desc";

    if (this.state.orderBy === property && this.state.order === "desc") {
      order = "asc";
    }

    this.setState({ order, orderBy });
  };

  handleSelectAllClick = (event, checked) => {
    if (checked) {
      this.setState(state => ({ selected: state.data.map(n => n.id) }));
      return;
    }
    this.setState({ selected: [] });
  };

  handleClick = (event, id) => {
    const { selected } = this.state;
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    this.setState({ selected: newSelected });
  };

  handleChangePage = (event, page) => {
    this.setState({ page });
  };

  handleChangeRowsPerPage = event => {
    this.setState({ rowsPerPage: event.target.value });
  };

  isSelected = id => this.state.selected.indexOf(id) !== -1;

  render() {
    const { classes, translate } = this.props;
    const { order, orderBy, selected, rowsPerPage, page, keyword } = this.state;

    let data = this.state.data;
    if (keyword !== null && keyword.length > 0) {
      let filters = keyword.split(",");
      filters =
        filters.length > 0
          ? filters
              .map(filter =>
                filter && filter.split("=").length === 2
                  ? {
                      id: filter.split("=")[0],
                      value: filter.split("=")[1]
                    }
                  : null
              )
              .filter(filter => filter !== null)
          : [];

      if (filters.length > 0) {
        data = data.filter(d =>
          filters.some(
            f =>
              (d[this.state.headers[f.id]] || "")
                .toString()
                .indexOf(f.value) !== -1
          )
        );
      } else {
        data = data.filter(d =>
          Object.keys(d).some(
            name =>
              (d[name] !== null ? d[name] : "").toString().indexOf(keyword) !==
              -1
          )
        );
      }
    }
    const emptyRows =
      rowsPerPage - Math.min(rowsPerPage, data.length - page * rowsPerPage);
    return (
      <Fragment>
        <Grid>
          <Grid item xs={12} sm={8} md={6} lg={4}>
            <TextField
              label={translate(`resources.progress.keyword.label`)}
              placeholder={translate(`resources.progress.keyword.placeholder`)}
              helperText={translate(`resources.progress.keyword.help`)}
              onChange={e => this.setState({ keyword: e.target.value })}
            />
          </Grid>
        </Grid>

        <div className={classes.tableWrapper}>
          <Table className={classes.table} aria-labelledby="tableTitle">
            <EnhancedTableHead
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={this.handleSelectAllClick}
              onRequestSort={this.handleRequestSort}
              rowCount={data.length}
            />
            <TableBody>
              {data
                .sort(getSorting(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map(n => {
                  return (
                    <TableRow
                      hover
                      onClick={event => this.handleClick(event, n.id)}
                      role="checkbox"
                      tabIndex={-1}
                      key={n.id}
                    >
                      {headers.map((header, i) => (
                        <TableCell
                          key={i}
                          style={
                            i === 0
                              ? {
                                  borderLeft: chooseColor(n),
                                  borderLeftWidth: 10,
                                  borderLeftStyle: "solid",
                                  paddingLeft: 4
                                }
                              : {}
                          }
                          padding={header.disablePadding ? "none" : "default"}
                          component={i === 0 ? "th" : "td"}
                          scope={i === 0 ? "row" : ""}
                          numeric={header.numeric}
                        >
                          <Typography
                            variant="body1"
                            component="a"
                            href={`#/items/${n.id}`}
                            target="_blank"
                            style={{
                              textDecoration: "none"
                            }}
                            rel="noopener noreferrer"
                          >
                            {!header.datetime || !n[header.id].format
                              ? n[header.id]
                              : n[header.id].format("DD/MM/YYYY HH:mm")}
                          </Typography>
                        </TableCell>
                      ))}
                    </TableRow>
                  );
                })}
              {emptyRows > 0 && (
                <TableRow style={{ height: 49 * emptyRows }}>
                  <TableCell colSpan={headers.length} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </div>
        <TablePagination
          component="div"
          count={data.length}
          rowsPerPage={rowsPerPage}
          page={page}
          backIconButtonProps={{
            "aria-label": "Previous Page"
          }}
          nextIconButtonProps={{
            "aria-label": "Next Page"
          }}
          onChangePage={this.handleChangePage}
          onChangeRowsPerPage={this.handleChangeRowsPerPage}
        />
        <Typography
          variant="caption"
          component="p"
          style={{ marginBottom: 20 }}
        >
          {translate("resources.progress.modified_warning")}
        </Typography>
      </Fragment>
    );
  }
}

EnhancedTable.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(translate(EnhancedTable));
