import {
  AUTH_CHECK,
  AUTH_ERROR,
  AUTH_GET_PERMISSIONS,
  AUTH_LOGIN,
  AUTH_LOGOUT,
} from "react-admin";

import { API_URL } from "./config";

export default (type, params) => {
  // called when the user attempts to log in
  if (type === AUTH_LOGIN) {
    const { username, password } = params;
    const requestURL = `${API_URL}/users/login`;
    const request = new Request(requestURL, {
      method: "POST",
      body: JSON.stringify({ username, password }),
      headers: new Headers({
        "Content-Type": "application/json",
        Accept: "application/json",
      }),
    });
    return fetch(request)
      .then((response) => response.json())
      .then(({ data }) => {
        if (data.code === 401) {
          throw new Error(data.message);
        }
        localStorage.setItem("username", data.username);
        localStorage.setItem("token", data.token);
        localStorage.setItem("role", data.role);
      });
  } else if (type === AUTH_GET_PERMISSIONS) {
    let role = localStorage.getItem("role");
    let permissions = [
      "items/list",
      "maintenance-requests/list",
      (role === "admin" && "users/list") || "",
      (role === "admin" && "progress/list") || "",
      ["admin", "user"].indexOf(role) !== -1 && "item-conflicts/list",
    ];

    return Promise.resolve({ permissions, role });
  }
  // called when the user clicks on the logout button
  if (type === AUTH_LOGOUT) {
    localStorage.removeItem("token");
    localStorage.removeItem("role");
    return Promise.resolve();
  }
  // called when the API returns an error
  if (type === AUTH_ERROR) {
    localStorage.removeItem("token");
    localStorage.removeItem("role");
    return Promise.reject();
  }
  // called when the user navigates to a new location
  if (type === AUTH_CHECK) {
    return localStorage.getItem("token") ? Promise.resolve() : Promise.reject();
  }
  return Promise.reject("Unknown method");
};
