import React from "react";
import { ReferenceInput, SelectInput, withTranslate } from "react-admin";

const TypologySelect = withTranslate(({ choices, translate, ...props }) => {
  return (
    <SelectInput
      {...props}
      choices={choices.map((choice) => ({
        ...choice,
        name: translate(choice.name),
      }))}
    />
  );
});

const TypologyInput = (props) => (
  <ReferenceInput {...props} reference="items/typologies" resource="items">
    <TypologySelect />
  </ReferenceInput>
);
export default TypologyInput;
