// import createIndexes from "./createIndexes";
import moment from "moment";
import preload from "./preload";

/**
 * Si occupa di verificare ed eventualmente aggiornare il database
 * locale riportando nuovi dati restituiti dal server remoto.
 *
 * Se il database non è mai stato creato, questo metodo esegue la prima
 * inizializzazione locale creando indici, tabelle e caricando l'intero
 * pacchetto dati dal server remoto (creando pacchetti da 1000 records)
 */
export default ({ inventoryDb, settingsDb, indexesDb, resource, callback }) =>
  settingsDb
    .get("sync")
    .then(sync =>
      preload({
        inventoryDb,
        settingsDb,
        indexesDb,
        resource,
        callback,
        lastSync: sync.timestamp,
        page: sync.page || 1,
        sync
      })
    )
    // L'unico caso in cui, si spera, vada in errore il sistema di sincronizzazione
    // è quando devo configurare per la prima volta i dati e non ho alcun record
    // all'interno del database dei settings (l'exception dovrebbe essere proprio un error
    // code 404, not_found).
    .catch(error =>
      preload({
        inventoryDb,
        settingsDb,
        indexesDb,
        resource,
        callback,
        sync: {
          _id: "sync",
          id: "sync",
          page: 1,
          timestamp: moment().format("YYYY-MM-DDTHH:mm:ss")
        }
      })
    )
    .finally(() =>
      settingsDb.upsert("sync", () => ({
        _id: "sync",
        id: "sync",
        timestamp: moment().format("YYYY-MM-DDTHH:mm:ss"),
        page: 1
      }))
    );
