import {
  BooleanField,
  ChipField,
  Datagrid,
  DateField,
  EditButton,
  List,
  Responsive,
  SimpleList,
  TextField,
} from "react-admin";

import React from "react";

const UserList = (props) => (
  <List {...props} perPage={10}>
    <Responsive
      small={
        <SimpleList
          primaryText={(record) => record.username}
          secondaryText={(record) => record.role}
        />
      }
      medium={
        <Datagrid>
          <TextField source="id" sortable />
          <BooleanField source="is_active" />
          <TextField source="username" sortable />
          <ChipField source="role" sortable />
          <DateField source="modified" showTime sortable />
          <EditButton />
        </Datagrid>
      }
    />
  </List>
);
export default UserList;
