export default {
  inventory_id: 0,
  new_inventory_id: 1,
  previous_inventory_id: 2,
  previous_inventory_id_2: 20,
  id_number: 3,
  id_number_from_notes: 4,
  name: 5,
  location: 6,
  floor: 7,
  coordinator: 8,
  new_location: 9
};
