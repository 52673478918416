import { blueGrey, deepOrange, red } from "@material-ui/core/colors";

import { createMuiTheme } from "@material-ui/core/styles";
import isOffline from "./offline/isOffline";

export const useDarkMode = () =>
  window.matchMedia &&
  window.matchMedia("(prefers-color-scheme: dark)").matches;
export default createMuiTheme({
  typography: {
    fontSize: 15,
  },
  palette: {
    background: {
      default: "#EDECEC",
    },
    type: useDarkMode() ? "dark" : "light",
    primary: deepOrange,
    secondary: isOffline() ? deepOrange : blueGrey,
    error: red,
  },
});
