import React from "react";
import { List as RaList, SimpleList, Responsive } from "react-admin";
import Filters from "./Filters";
import { Chip, Avatar, Typography, Divider } from "@material-ui/core";
import NewReleasesIcon from "@material-ui/icons/NewReleases";
import BuildIcon from "@material-ui/icons/Build";
import { red, orange } from "@material-ui/core/colors";
import moment from "moment";
import { translate } from "ra-core";
import SmartTextField from "./fields/SmartTextField";
import SmartDateField from "./fields/SmartDateField";
import Show from "./Show";
import Datagrid from "../components/list/Datagrid";
import exporter from "./exporter";
const List = ({ translate, ...props }) => (
  <RaList
    {...props}
    perPage={300}
    pagination={null}
    exporter={exporter.bind(this, translate)}
    filters={<Filters />}
    sort={{ field: "date", order: "DESC" }}
    bulkActionButtons={false}
  >
    <Responsive
      small={
        <SimpleList
          linkType={false}
          primaryText={record => (
            <Typography component="h2" variant="headline">
              {(record.date && moment(record.date).format("DD/MM/YYYY")) || ""}
              <Divider />
              <br />
            </Typography>
          )}
          secondaryText={record => (
            <Typography component="div">
              <Chip
                color="secondary"
                style={{ backgroundColor: orange[500] }}
                avatar={
                  <Avatar style={{ backgroundColor: orange[500] }}>
                    <BuildIcon />
                  </Avatar>
                }
                label={`${translate("resources.progress.fields.changed")}: ${
                  record.changed
                }`}
              />
            </Typography>
          )}
          tertiaryText={record => (
            <Chip
              color="secondary"
              style={{ backgroundColor: red[500] }}
              avatar={
                <Avatar style={{ backgroundColor: red[500] }}>
                  <NewReleasesIcon />
                </Avatar>
              }
              label={`${translate("resources.progress.fields.assigned")}: ${
                record.assigned
              }`}
            />
          )}
        />
      }
      medium={
        <Datagrid rowClick="expand" expand={<Show />}>
          <SmartDateField source="date" />
          <SmartTextField source="added" sortable={false} />
          <SmartTextField source="changed" sortable={false} />
          <SmartTextField source="assigned" sortable={false} />
          <SmartTextField source="progress" sortable={false} />
        </Datagrid>
      }
    />
  </RaList>
);

export default translate(List);
