import React from "react";
import {
  Filter,
  DateInput,
  SelectInput,
  ReferenceInput,
  AutocompleteInput
} from "react-admin";
const Filters = props => (
  <Filter {...props}>
    <DateInput source="start" alwaysOn />
    <DateInput source="end" alwaysOn />
    <ReferenceInput
      source="user_id"
      reference="users"
      label="resources.progress.fields.user"
    >
      <SelectInput optionText="username" />
    </ReferenceInput>
    <ReferenceInput
      source="new_location"
      reference="items/new_location/distinct"
    >
      <AutocompleteInput optionText="name" />
    </ReferenceInput>
  </Filter>
);
export default Filters;
