export default [
  "DISTRETTO A - NOVASIRI",
  "DISTRETTO A - ROTONDELLA",
  "DISTRETTO A - TURSI",
  "DISTRETTO A STIGLIANO",
  "DISTRETTO ACCETTURA",
  "DISTRETTO ALIANO",
  "DISTRETTO AREA MATERA",
  "DISTRETTO AREA MONTALBANO",
  "DISTRETTO B FERRANDINA",
  "DISTRETTO B GARAGUSO",
  "DISTRETTO B GRASSANO GROTTOLE SALANDRA",
  "DISTRETTO B METAPONTO",
  "DISTRETTO B TRICARICO",
  "DISTRETTO CIRIGLIANO",
  "DISTRETTO COLOBRARO",
  "DISTRETTO CRACO",
  "DISTRETTO GORGOGLIONE",
  "DISTRETTO IRSINA",
  "DISTRETTO MIGLIONICO",
  "DISTRETTO MONTESCAGLIOSO",
  "DISTRETTO OLIVETO",
  "DISTRETTO PISTICCI",
  "DISTRETTO POLICORO",
  "DISTRETTO POMARICO",
  "DISTRETTO SAN GIORGIO LUCANO",
  "DISTRETTO SAN MAURO FORTE",
  "DISTRETTO SCANZANO",
  "DISTRETTO VALSINNI",
  "MATERA - DISTRETTI VARI",
  "MATERA CASA CIRCONDARIALE",
  "MATERA CENTRO DIURNO",
  "MATERA CSM",
  "MATERA GUARDIA MEDICA",
  "MATERA SERT",
  "OSPEDALE DISTRETTUALE STIGLIANO",
  "POLICORO SERT",
  "POLICORO SPPILL",
  "PRESIDIO OSPEDALIERO MATERA",
  "PRESIDIO OSPEDALIERO POLICORO",
  "PRESIDIO OSPEDALIERO TINCHI",
  "PRESIDIO OSPEDALIERO TRICARICO",
  "PREVENZIONE BERNALDA",
  "PREVENZIONE CALCIANO",
  "PREVENZIONE FERRANDINA",
  "PREVENZIONE MARCONIA",
  "PREVENZIONE MIGLIONICO",
  "PREVENZIONE STIGLIANO",
  "SEDE CENTRALE - MATERA",
  "TINCHI REMS",
];
