import React from "react";
import compose from "recompose/compose";
import Card from "@material-ui/core/Card";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import { withStyles } from "@material-ui/core/styles";
import { translate } from "react-admin";
import CardIcon from "./CardIcon";
import EditIcon from "@material-ui/icons/PanTool";

import { Typography, Divider } from "@material-ui/core";
import { Link } from "ra-ui-materialui";
import { orange } from "@material-ui/core/colors";

const styles = theme => ({
  main: {
    flex: "1",
    marginTop: 20,
    marginRight: "1em"
  },
  titleLink: { textDecoration: "none", color: "inherit" },
  card: {
    padding: "16px 0",
    overflow: "inherit",
    textAlign: "right"
  },
  title: {
    padding: "0 16px"
  },
  value: {
    padding: "0 16px",
    minHeight: 48
  },
  listItem: {
    paddingBottom: 0
  },
  listItemText: {
    overflowY: "hidden",
    height: "1.5em",
    textAlign: "right",
    fontFamily: "Fira Code, Consolas, Courier New"
  },
  button: {
    margin: theme.spacing.unit
  }
});

const PendingChanges = ({ changes = [], translate, classes, button }) =>
  changes.length > 0 ? (
    <div className={classes.main}>
      <CardIcon Icon={EditIcon} bgColor={orange[500]} />
      <Card className={classes.card}>
        <Typography className={classes.title} color="textSecondary">
          {translate("app.sync.changes.name")}
        </Typography>

        <Typography
          className={classes.title}
          variant="caption"
          component="span"
        >
          {translate("app.sync.changes.help")}
          <Divider />
        </Typography>

        <List dense>
          {changes.map((change, i) => (
            <ListItem className={classes.listItem} key={`change-${i}`}>
              <ListItemText
                primary={
                  <Link to={`/items/${change.id}`}>
                    {change.inventory_id} ({change.new_inventory_id}) -
                    {change.name}
                  </Link>
                }
                className={classes.listItemText}
                style={{ paddingRight: 0 }}
              />
            </ListItem>
          ))}
        </List>
        {button && changes.length > 0 && (
          <Typography
            className={classes.title}
            variant="caption"
            component="span"
          >
            <Divider />
            {button && <br />}
            {button}
          </Typography>
        )}
      </Card>
    </div>
  ) : null;

const enhance = compose(withStyles(styles), translate);

export default enhance(PendingChanges);
