import { Admin, Resource } from "react-admin";

import Dashboard from "./dashboard";
import Layout from "./components/Layout";
import Menu from "./Menu";
import React from "react";
import addAuthHeaderFeature from "./addAuthHeaderFeature";
import addItemsFeature from "./addItemsFeature";
import addOfflineDataFeature from "./addOfflineDataFeature";
import addUploadFeature from "./addUploadFeature";
import authProvider from "./authProvider";
import dataProvider from "./dataProvider";
import englishMessages from "ra-language-english";
import errorSagas from "./sagas/errors";
import italianMessages from "./i18n/it";
import itemConflicts from "./item-conflicts";
import items from "./items";
import maintenanceRequests from "./maintenance-requests";
import progress from "./progress";
import theme from "./theme";
import users from "./users";

const messages = {
  it: italianMessages,
  en: englishMessages,
};
const i18nProvider = (locale) => messages[locale];

let dp = addAuthHeaderFeature(dataProvider);
dp = addOfflineDataFeature(dp);
dp = addUploadFeature(dp);
dp = addItemsFeature(dp);

class App extends React.Component {
  render() {
    return (
      <Admin
        title="Inventory"
        appLayout={Layout}
        customSagas={[errorSagas]}
        theme={theme}
        locale="it"
        menu={Menu}
        authProvider={authProvider}
        i18nProvider={i18nProvider}
        dataProvider={dp}
        dashboard={Dashboard}
      >
        <Resource name="items" {...items} />
        <Resource name="item-conflicts" {...itemConflicts} />
        <Resource name="users" {...users} />
        <Resource name="progress" {...progress} />
        <Resource name="maintenance-requests" {...maintenanceRequests} />
        <Resource name="items/lots" />
        <Resource name="items/typologies" />
        <Resource name="items/new_cost_center/distinct" />
        <Resource name="items/new_location/distinct" />
        <Resource name="items/coordinator/distinct" />
        <Resource name="items/location/distinct" />
        <Resource name="items/class/distinct" />
      </Admin>
    );
  }
}
export default App;
