export default params =>
  Object.keys(params.filter).reduce((object, param) => {
    let value = params.filter[param];
    let filterObject;
    switch (param) {
      case "has_new_inventory_id":
        filterObject = { new_inventory_id: { $gt: 0 } };
        break;
      case "location":
      case "id_number":
      case "id_number_from_notes":
      case "coordinator":
        filterObject = { [param]: { $regex: value } };
        break;
      default:
        filterObject = { [param]: { $eq: value } };
        break;
    }
    return {
      ...object,
      ...filterObject
    };
  }, {});
