import React from "react";
import {
  Edit,
  SimpleForm,
  TextInput,
  SelectInput,
  BooleanInput,
} from "react-admin";

const UserEdit = (props) => (
  <Edit {...props} undoable={false}>
    <SimpleForm redirect="list">
      <BooleanInput source="is_active" />
      <TextInput source="username" />
      <TextInput source="password" type="password" />
      <TextInput source="email" type="email" />
      <SelectInput
        source="role"
        choices={[
          { id: "admin", name: "admin" },
          { id: "user", name: "user" },
          { id: "reader", name: "reader" },
        ]}
      />
    </SimpleForm>
  </Edit>
);
export default UserEdit;
