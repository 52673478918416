import React from "react";
import {
  Show,
  SimpleShowLayout,
  TextField,
  BooleanField,
  DateField
} from "react-admin";

const ItemConflictShow = props => (
  <Show {...props}>
    <SimpleShowLayout>
      <TextField source="user.username" />
      <BooleanField source="is_electromedical" />
      <TextField source="inventory_id" />
      <TextField source="previous_inventory_id" />
      <TextField source="previous_inventory_id_2" />
      <TextField source="new_inventory_id" />
      <TextField source="name" />
      <TextField source="new_location" />
      <TextField source="brand" />
      <TextField source="model" />
      <TextField source="id_number" />
      <TextField source="class" />
      <TextField source="new_cost_center" />
      <TextField source="floor" />
      <TextField source="notes" />
      <DateField source="modified" showTime />
    </SimpleShowLayout>
  </Show>
);
export default ItemConflictShow;
