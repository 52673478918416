import { downloadCSV } from "react-admin";
import { unparse as convertToCSV } from "papaparse/papaparse.min";

const COLUMNS_TO_EXPORT = [
  "id",
  "inventory_id",
  "previous_inventory_id",
  "previous_inventory_id_2",
  "new_inventory_id",
  "name",
  "new_location",
  "brand",
  "model",
  "id_number",
  "class",
  "new_cost_center",
  "floor",
  "notes",
  "is_electromedical"
];
const exporter = (translate, items) => {
  const data = items
    .map(i => ({
      ...i,
      username: i.user && i.user !== null ? i.user.username : "",
      status: "C"
    }))
    .map(item =>
      COLUMNS_TO_EXPORT.reduce(
        (reduction, column) => ({
          ...reduction,
          [translate(`resources.items.fields.${column}`)]: item[column]
        }),
        {}
      )
    );
  const csv = convertToCSV(data, {
    delimiter: ";",
    encoding: "utf-8"
  });
  downloadCSV("\uFEFF" + csv, "items");
};
export default exporter;
