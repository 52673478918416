import React from "react";
import {
  Filter,
  TextInput,
  BooleanInput,
  NumberInput,
  ReferenceInput,
  SelectInput,
  AutocompleteInput
} from "react-admin";

import { WithPermissions } from "ra-core";

const createFilterProps = (name, props) => ({
  InputProps: {
    onKeyDown: e => (e.keyCode === 8 && props.showFilter(name, "")) || true
  }
});

const ItemFilter = props => (
  <WithPermissions
    render={({ permissions }) => (
      <Filter {...props}>
        <NumberInput
          source="inventory_id"
          style={{ minWidth: 182 }}
          options={{
            inputProps: {
              min: 0,
              step: 1,
              pattern: "[0-9]*"
            }
          }}
          alwaysOn
        />
        <TextInput source="name" style={{ minWidth: 200 }} />
        <NumberInput
          source="new_inventory_id"
          style={{ minWidth: 182 }}
          options={{
            inputProps: {
              min: 0,
              step: 1,
              pattern: "[0-9]*"
            },
            ...createFilterProps("new_inventory_id", props)
          }}
          defaultValue=""
        />
        <NumberInput
          source="previous_inventory_id"
          style={{ minWidth: 182 }}
          options={{
            inputProps: {
              min: 0,
              step: 1,
              pattern: "[0-9]*"
            },
            ...createFilterProps("previous_inventory_id", props)
          }}
        />
        <NumberInput
          style={{ minWidth: 182 }}
          source="previous_inventory_id_2"
          options={{
            inputProps: {
              min: 0,
              step: 1,
              pattern: "[0-9]*"
            },
            ...createFilterProps("previous_inventory_id_2", props)
          }}
        />
        <TextInput
          source="id_number"
          options={createFilterProps("id_number", props)}
        />
        <ReferenceInput source="class" reference="items/class/distinct">
          <AutocompleteInput
            optionText="name"
            options={createFilterProps("class", props)}
          />
        </ReferenceInput>

        <ReferenceInput
          source="new_location"
          reference="items/new_location/distinct"
        >
          <AutocompleteInput
            optionText="name"
            options={createFilterProps("new_location", props)}
          />
        </ReferenceInput>

        <BooleanInput source="is_electromedical" />
        {permissions && permissions.role === "admin" && (
          <ReferenceInput
            source="user_id"
            reference="users"
            label="resources.progress.fields.user"
          >
            <SelectInput optionText="username" />
          </ReferenceInput>
        )}
      </Filter>
    )}
  />
);
export default ItemFilter;
