import React from "react";
import { Edit, TabbedForm, Toolbar } from "react-admin";
import Fields from "./Fields";
import SaveButtonWithConfirmation from "./SaveButtonWithConfirmation";

const ItemTitle = ({ record }) => (
  <span>
    {record.inventory_id}/{record.new_inventory_id}
  </span>
);

const ItemToolbar = ({ permissions, ...props }) => (
  <Toolbar {...props}>
    <SaveButtonWithConfirmation
      disabled={props.pristine || !permissions || permissions.role === "reader"}
      redirect="list"
      submitOnEnter={false}
    />
  </Toolbar>
);
const ItemEdit = (props) => (
  <Edit {...props} undoable={false} title={<ItemTitle />}>
    <TabbedForm
      toolbar={<ItemToolbar permissions={props.permissions} />}
      redirect="list"
    >
      {Fields(false)}
    </TabbedForm>
  </Edit>
);
export default ItemEdit;
