export default [
  { id: "name", numeric: false, disablePadding: true, width: "170px" },
  {
    id: "inventory_id",
    label: "inventory_id_abbr",
    numeric: true,
    disablePadding: false,
    width: "40px"
  },
  {
    id: "new_inventory_id",
    label: "new_inventory_id_abbr",
    numeric: true,
    disablePadding: false,
    width: "40px"
  },

  { id: "brand", numeric: false, disablePadding: false },
  { id: "model", numeric: false, disablePadding: false },
  { id: "notes", numeric: false, disablePadding: false },
  { id: "new_location", numeric: false, disablePadding: false },
  { id: "modified", numeric: false, datetime: true, disablePadding: false }
];
