import makeid from "../utils/makeid";
import { CREATE } from "ra-core";
import isOffline from "./isOffline";
import createDescribedId from "./createDescribedId";

export default ({
  inventoryDb,
  changesDb,
  resource,
  type,
  params,
  creationId
}) => {
  let id =
    type === CREATE
      ? `${resource}/${makeid(10)}`
      : params.data._id || createDescribedId({ record: params.data });
  let record = () => ({
    ...params.data,
    new: !params.data.id || params.data.id <= 0,
    status: "pending",
    _id: id
  });
  if (!isOffline()) {
    // Sto allineando i dati locali a quelli del server remoto.
    return inventoryDb
      .upsert(id, record)
      .then(() => ({ success: true, data: params.data }));
  }

  return (
    changesDb
      // Aggiorno il database delle modifiche pending.
      .upsert(id, record)
      .then(_ =>
        inventoryDb
          // Aggiorno il database globale per tenere i dati allineati.
          .upsert(id, record)
          .then(_ => ({
            data: {
              success: true,
              id,
              data: {
                id,
                ...params.data
              }
            }
          }))
      )
  );
};
