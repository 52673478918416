import { downloadCSV } from "react-admin";
import { unparse as convertToCSV } from "papaparse/papaparse.min";

const COLUMNS_TO_EXPORT = ["date", "added", "changed", "assigned", "progress"];
const exporter = (translate, items) => {
  const data = items.map(item =>
    COLUMNS_TO_EXPORT.reduce(
      (reduction, column) => ({
        ...reduction,
        [translate(`resources.progress.fields.${column}`)]: item[column]
      }),
      {}
    )
  );
  const csv = convertToCSV(data, {
    delimiter: ";"
  });
  downloadCSV("\uFEFF" + csv, "progress");
};
export default exporter;
