/**
 * Recupera informazioni statistiche sull'utilizzo del database locale.
 */
export default ({ changesDb, settingsDb }) =>
  settingsDb
    .get("sync")
    .then(sync =>
      changesDb.allDocs().then(({ rows }) =>
        Promise.all(rows.map(row => changesDb.get(row.id))).then(changes => ({
          data: {
            timestamp: sync.timestamp,
            changes
          }
        }))
      )
    )
    .catch(_ => ({
      data: {
        timestamp: null,
        changes: null
      }
    }));
