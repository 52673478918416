import React, { Fragment } from "react";
import { Confirm, translate } from "react-admin";
import { compose } from "recompose";
import makeid from "../utils/makeid";
import SaveButton from "../components/button/SaveButton";
import { connect } from "react-redux";
import { getFormValues } from "redux-form";

class SaveButtonWithConfirmation extends React.Component {
  state = {
    session: makeid(10),
    isOpen: false
  };

  handleClose = () => {
    this.setState({ isOpen: false, session: makeid(10) });
  };

  handleConfirm = () => {
    const { handleSubmitWithRedirect, redirect } = this.props;
    this.setState({ isOpen: false, session: makeid(10) }, () => {
      handleSubmitWithRedirect(redirect)();
    });
  };

  render() {
    const { dispatch, translate, formValues, ...props } = this.props;
    return (
      <Fragment>
        <SaveButton
          label={translate("ra.action.save")}
          {...this.props}
          onClick={() =>
            formValues.new_inventory_id === null && !formValues.is_abandoned
              ? this.setState({ isOpen: true })
              : this.handleConfirm()
          }
        />
        <Confirm
          {...props}
          key={this.state.session}
          isOpen={this.state.isOpen}
          title={"resources.items.titles.confirm_unsafe_save"}
          content={"resources.items.messages.confirm_unsafe_save"}
          onConfirm={this.handleConfirm}
          onClose={this.handleClose}
        />
      </Fragment>
    );
  }
}
const mapStateToProps = state => ({
  formValues: getFormValues("record-form")(state)
});

export default compose(
  translate,
  connect(mapStateToProps)
)(SaveButtonWithConfirmation);
