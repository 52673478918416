import React from "react";
import { Badge } from "@material-ui/core";
import InsertCommentIcon from "@material-ui/icons/InsertComment";
const ChangesField = ({ record }) =>
  record.changes && record.changes.length > 0 ? (
    <Badge badgeContent={record.changes.length || 0} color="primary">
      <InsertCommentIcon />
    </Badge>
  ) : (
    ""
  );
export default ChangesField;
