import italianMessages from "ra-language-italian";

const messages = {
  ...italianMessages,
  app: {
    yes: "Si",
    no: "No",
    no_data: "Nessun dato pervenuto",
    loading: "Caricamento in corso...",
    offline: `L'applicazione è offline, dovrai trasferire manualmente tutte le attività di modifica.`,
    delete_not_permitted: "La cancellazione offline non è permessa",
    last_written_value:
      "Ultimo valore utilizzato (clicca sul valore per sostiuirlo a quello corrente presente sopra):",
    abandoned: "Bene Dismesso!",
    welcome: "Benvenuta/o %{username}",

    progress: {
      name: "Progresso",
      help:
        "Mostra numeri e percentuale sull'avanzamento delle attività di assegnazione dei nuovi numeri di inventario",
    },
    sync: {
      title: "Sincronizzazione in corso...",
      invalid_data: `I dati offline non sono aggiornati, è necessario effettuare una sincronizzazione.`,
      message: [
        "E' in corso l'aggiornamento dei dati sull'inventario,",
        "l'operazione potrebbe richiedere alcuni minuti.",
      ].join(" "),
      pendings: "Modifiche in attesa di essere trasmesse",
      warnings: [
        "Attenzione: hai apportato modifiche che non sono state ancora trasmesse, ",
        "assicurati di avere linea 3G/4G e clicca sull'icona refresh (in alto a destra)",
        "per tentare un aggiornamento. In caso di fallimento puoi riprovare spostandoti",
        "in una zona in cui c'è campo.",
      ].join(" "),
      timestamp: "Ultimo aggiornamento",
      refresh: {
        name: "Scarica",
        help: [
          "Puoi rigenerare completamente il database cliccando sul tasto presente di seguito. ",
          "La rigenerazione non comporta la perdita dei dati in coda",
        ].join(" "),
      },
      push: {
        name: "Invia modifiche",
        help: [
          "Cliccando su invia modifiche, tutte le attività che hai svolto in modalità aereo/offline",
          "saranno inoltrate al server centrale.",
        ].join(" "),
      },
      changes: {
        name: "Modifiche locali",
        help: "Elenco dei beni creati e/o modificati non ancora sincronizzati",
        clear: "Cancella",
      },
    },
  },
  menu: {
    groups: {
      dashboard: "Cruscotto",
    },
    items: {
      items: "Beni",
      "item-conflicts": "Conflitti",
      "maintenance-requests": "Manutenzione",
      "maintenance-requests-new": "Richiedi Manutenzione",
      users: "Utenti",
      progress: "Progresso",
      dashboard: "Dashboard",
    },
  },
  resources: {
    users: {
      name: "Utente |||| Utenti",
      fields: {
        username: "Utente",
      },
    },
    items: {
      name: "Bene |||| Beni",
      added: "Caricato a sistema",
      lots: {
        null: "Nessuno",
        lot_a: "Lotto A",
        lot_b: "Lotto B",
        lot_c: "Lotto C",
      },
      typologies: {
        null: "Nessuna",
        owned: "Proprietà",
        service: "Service",
      },
      fields: {
        id: "#ID",
        lot: "Lotto",
        typology: "Tipologia",
        has_pics: "Con Foto",
        is_electromedical: "Elettromedicale",
        is_checked_with_clinical_engineers: "Controllato con Ing. Clinica",
        is_found: "Trovato",
        has_new_inventory_id: "Inventariato",
        new_inventory_id: "Nuovo Numero Inventario",
        new_inventory_id_abbr: "N",
        new_inventory_id_timestamp:
          "Data e ora assegnazione nuovo numero di inventario",
        inventory_id: "T1: NUMERO INV - RFID",
        inventory_id_abbr: "T1",
        previous_inventory_id: "T2: N° INV ASM O USL 4",
        previous_inventory_id_abbr: "T2",
        previous_inventory_id_2: "T3: N° INV USL 4 O ASL5",
        previous_inventory_id_2_abbr: "T3",
        name: "Nome",
        implementation_date: "Data collaudo",
        cost_center: "Centro di costo",
        location: "Ubicazione",
        new_location: "Descrizione presidio",
        brand: "Marca",
        model: "Modello",
        id_number: "Matricola",
        id_number_from_notes: "Matricola da note",
        class: "Classe",
        new_cost_center: "Nuovo centro di costo",
        district: "Presidio/Distretto",
        department: "Dipartimento",
        responsibility_center: "Centro di responsabilità",
        coordinator: "Coordinatore",
        building: "Edificio",
        floor: "Piano/Stanza",
        unit: "Reparto",
        location_ch4: "Ubicazione da CH4",
        is_abandoned: "Da Dismettere",
        is_abandoned_1: "Dismesso",
        notes: "Note",
        user: "Utente",
        status: "Stato",
        pics_count: "Foto",
        username: "Ultimo Utente",
        modified: "Ultima modifica",
      },
      titles: {
        confirm_unsafe_save: "Attenzione",
        import: "Importa File Excel",
      },
      messages: {
        import: "Seleziona il file Excel da importare.",
        import_message:
          "Import CSV in corso, prendi un caffè per ingannare l'attesa...",
        import_drop_zone: "Clicca o trascina qui il file da importare.",
        confirm_unsafe_save: [
          "Sei sicuro di voler salvare senza aver inserito il nuovo numero inventario?",
          "Sei consapevole che potresti essere beccato dal Sig. Lacasa con le mani nel sacco e senza numero di inventario?",
          "Conferma per procedere.",
        ].join(" "),
        records_found:
          "Sono stati restituti %{total} beni in base ai filtri di ricerca inseriti.",
        user_filter_warning: [
          "Ho notato che stai filtrando per utente, fai attenzione a non confonderti: potrai notare dei record in cui la colonna utente",
          "non corrisponde. E' tutto normale, sicuramente è un bene inventariato su cui hanno lavorato più persone e, tra queste, figura",
          "anche quella che stai utilizzando come filtro, quindi ti pregherei di non infastidire roberto con eventuali e presunti bugs.",
        ].join(" "),
        no_filters:
          "Devi specificare almeno un filtro valido per visualizzare dei risultati quando sei in modalità offline.",
      },
      actions: {
        import: "Importa",
      },
    },
    "item-conflicts": {
      fields: {
        id: "#ID",
        is_electromedical: "Elettromedicale",
        new_inventory_id: "Nuovo Numero Inventario",
        inventory_id: "T1: NUMERO INV - RFID",
        previous_inventory_id: "T2: N° INV ASM O USL 4",
        previous_inventory_id_2: "T3: N° INV USL 4 O ASL5",
        name: "Nome",
        new_location: "Descrizione presidio",
        brand: "Marca",
        model: "Modello",
        id_number: "Matricola",
        class: "Classe",
        new_cost_center: "Nuovo centro di costo",
        floor: "Piano/Stanza",
        notes: "Note",
        modified: "Ultima modifica",
        user: {
          username: "Utente",
        },
      },
      messages: {
        user_filter_warning:
          "Sono stati restituti %{total} conflitti in base ai filtri di ricerca inseriti.",
      },
    },
    "maintenance-requests": {
      name: "Richiesta di Manutenzione |||| Richieste di manutenzione",
      buttons: {
        send: "Invia Richiesta",
      },
      titles: {
        sent: "Richiesta inoltrata correttamente!",
      },
      messages: {
        sent:
          "Riceverai una copia della mail anche sull'indirizzo che hai indicato nel modulo.",
      },
      fields: {
        device: "Dispositivo",
        brand: "Marca",
        model: "Modello",
        inventory_id: "N. Inventario",
        inventory_id_help:
          "Inserire almeno una foto del n° inventario nella sezione Allegati",
        serial_number: "S/N",
        notes: "Note",
        defect: "Difetto riscontrato",
        district: "Presidio",
        department: "Reparto",
        floor: "Piano",
        room: "Stanza",
        access_times: "Orari di accesso",
        referent: "Referente",
        phone: "Telefono",
        email: "Email",
        attachments: "Allegati",
      },
    },
    progress: {
      name: "Progresso |||| Progressi",
      warn: "I totali non consentono la visualizzazione del dettaglio",
      keyword: {
        label: "Chiave di ricerca",
        placeholder: "Inserisci una chiave di ricerca",
        help: [
          "Es. T1=39,Note=non inventariare oppure testo libero.",
          "Nota bene: i filtri con operatore = (uguale) sono comunque applicati come LIKE.",
          "L'uguale serve ad evidenziare la necessità di filtrare su campi specifici.",
        ].join(" "),
      },
      modified_warning: [
        "Nota bene: la data di ultima modifica del bene potrebbe differire dal giorno aggregato",
        "a causa di eventuali ed ulteriori variazioni che si sono susseguite nel tempo da parte dello stesso ",
        "o di altri utenti.",
      ].join(" "),
      fields: {
        date: "Data",
        totals: "Totali",
        progress: "Avanzamento %",
        new_location: "Descrizione presidio",
        added: "Beni Creati",
        changed: "Beni Modificati",
        assigned: "Beni Inventariati",
        worked: "Beni Lavorati",
        start: "Dalla data",
        end: "Alla data",
        user: "Utente",
      },
    },
  },
};

export default messages;
