import { CREATE, UPDATE } from "ra-core";

const processDoc = ({ changesDb, inventoryDb, item, row }) =>
  changesDb
    .upsert(row.id, () => ({
      ...item,
      _deleted: true
    }))
    .then(() =>
      inventoryDb
        .upsert(row.id, () => ({ _deleted: true }))
        .then(() => ({ success: true, item }))
    );
/**
 * Rimanda tutte le modifiche che risiedono sul DB locale del browser
 * direttamente al server remoto su cui è eseguita una chiamata POST
 * per ogni record gestito localmente.
 */
export default ({ resource, inventoryDb, changesDb, requestHandler }) =>
  changesDb.allDocs().then(data =>
    Promise.all(
      data.rows.map(row =>
        changesDb
          .get(row.id)
          .then(item =>
            requestHandler(item.new ? CREATE : UPDATE, resource, {
              id: item.new ? null : item.id,
              data: {
                ...item,
                pics: item.pics || [],
                // Questo parametro serve ad indicare al server alcuni ragionamenti
                // in più che vanno fatti per la gestione del dato offline.
                offline: true,
                user: null
              }
            })
              .then(() => processDoc({ changesDb, inventoryDb, item, row }))
              .catch(error => processDoc({ changesDb, inventoryDb, item, row }))
          )
          .catch(item => ({
            item,
            success: true,
            message: "Already removed."
          }))
      )
    )
      .then(results => ({
        data: {
          success: results.filter(r => r.success),
          failed: results.filter(r => !r.success)
        }
      }))
      .catch(error => ({
        data: {
          error,
          data,
          failed: true,
          success: false
        }
      }))
  );
