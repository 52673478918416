import { MaintenanceRequestIcon } from "../icons";
import Create from "./Create";
import Show from "./Show";

export default {
  options: {
    group: "dashboard",
  },
  icon: MaintenanceRequestIcon,
  show: Show,
  create: Create,
};
