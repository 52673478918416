import React from "react";
import { Create, TabbedForm } from "react-admin";
import Fields from "./Fields";

const ItemCreate = props => (
  <Create {...props}>
    <TabbedForm redirect="list">{Fields(true)}</TabbedForm>
  </Create>
);
export default ItemCreate;
