import React from "react";
import WarningIcon from "@material-ui/icons/Warning";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import { Chip, Avatar } from "@material-ui/core";
import { translate } from "ra-core";
import chooseColor from "../chooseColor";
const InventoryIdField = ({ translate, source, record, ...props }) => (
  <Chip
    style={{
      backgroundColor: chooseColor(record)
    }}
    color="secondary"
    avatar={
      <Avatar
        style={{
          backgroundColor: chooseColor(record)
        }}
      >
        {record.is_abandoned || record.is_abandoned_1 ? (
          <WarningIcon
            style={{
              backgroundColor: chooseColor(record)
            }}
          />
        ) : (
          <CheckCircleIcon
            style={{
              backgroundColor: chooseColor(record)
            }}
          />
        )}
      </Avatar>
    }
    label={`${record[source] || "N.D."} ${
      record.is_abandoned || record.is_abandoned_1
        ? " - " + translate("app.abandoned")
        : ""
    }`}
  />
);

export default translate(InventoryIdField);
