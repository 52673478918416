import React, { Fragment } from "react";
import {
  List,
  SimpleList,
  Responsive,
  Datagrid,
  TextField,
  DateField,
  ShowButton
} from "react-admin";
import Filters from "./Filters";
import { Typography } from "@material-ui/core";
import Pagination from "./Pagination";
import WarnBox from "./WarnBox";
import { WithPermissions, translate } from "ra-core";
import RecordsCount from "./RecordsCount";
import exporter from "./exporter";
import ListActions from "./ListActions";
import WarnAboutFilters from "./WarnAboutFilters";

const MyDatagrid = ({ large, ...props }) => (
  <Fragment>
    <WarnBox {...props} />
    <RecordsCount {...props} />
    <WarnAboutFilters {...props} />
    <Datagrid {...props}>
      <TextField source="inventory_id" />
      <TextField source="new_inventory_id" />
      <TextField source="name" />
      <TextField source="new_location" />
      <TextField source="new_cost_center" />
      <DateField source="modified" showTime />
      <ShowButton />
    </Datagrid>
  </Fragment>
);
const MySimpleList = translate(({ translate, ...props }) => (
  <Fragment>
    <WarnBox {...props} />
    <RecordsCount {...props} />
    <SimpleList
      {...props}
      linkType="show"
      pagination={<Pagination />}
      primaryText={record => (
        <Typography variant="title">{record.name}</Typography>
      )}
      secondaryText={record => (
        <Typography component="div">
          <Typography variant="body1" component="span">
            {record.new_location}
            <br />
            {record.new_cost_center}
          </Typography>
          {record.user && (
            <Typography variant="caption" component="span">
              {record.user.username}
            </Typography>
          )}
        </Typography>
      )}
    />
  </Fragment>
));
const ItemList = ({ translate, ...props }) => (
  <WithPermissions
    render={({ permissions }) => {
      const additionalProps = {};
      if (!permissions || !permissions.role || permissions.role !== "admin") {
        additionalProps.bulkActionButtons = null;
      }
      return (
        <List
          {...props}
          {...additionalProps}
          perPage={20}
          actions={<ListActions />}
          sort={{ field: "modified", order: "DESC" }}
          exporter={exporter.bind(this, translate)}
          filters={<Filters />}
        >
          <Responsive
            small={<MySimpleList />}
            medium={<MyDatagrid />}
            large={<MyDatagrid large />}
          />
        </List>
      );
    }}
  />
);
export default translate(ItemList);
