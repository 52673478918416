import React from "react";
import { TextField } from "react-admin";
import { Typography, withStyles } from "@material-ui/core";
import { compose } from "recompose";
import commonStyles from "./commonStyles";
const SmartTextField = ({ classes, source, record, ...props }) =>
  record.id === 0 ? (
    <Typography variant="body2" className={classes.footerLabel}>
      {record[source]}
    </Typography>
  ) : (
    <TextField source={source} record={record} {...props} />
  );

export default compose(withStyles(commonStyles))(SmartTextField);
