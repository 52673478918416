import React from "react";
import PropTypes from "prop-types";
import { translate } from "react-admin";
import { compose } from "recompose";
import {
  TableHead,
  TableRow,
  TableCell,
  Tooltip,
  TableSortLabel
} from "@material-ui/core";
import headers from "./headers";
const EnhancedTableHead = ({ translate, onRequestSort, order, orderBy }) => (
  <TableHead>
    <TableRow>
      {headers.map(row => {
        return (
          <TableCell
            key={row.id}
            numeric={row.numeric}
            width={row.width || "auto"}
            padding={row.disablePadding ? "none" : "default"}
            sortDirection={orderBy === row.id ? order : false}
          >
            <Tooltip
              title="Sort"
              placement={row.numeric ? "bottom-end" : "bottom-start"}
              enterDelay={300}
            >
              <TableSortLabel
                active={orderBy === row.id}
                direction={order}
                onClick={e => onRequestSort(e, row.id)}
              >
                {translate(`resources.items.fields.${row.label || row.id}`)}
              </TableSortLabel>
            </Tooltip>
          </TableCell>
        );
      }, this)}
    </TableRow>
  </TableHead>
);

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.string.isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired
};

export default compose(translate)(EnhancedTableHead);
