import { ItemIcon } from "../icons";
import List from "./List";
import Edit from "./Edit";
import Create from "./Create";

export default {
  options: {
    group: "dashboard",
    accessible: true,
    offline: true
  },
  icon: ItemIcon,
  list: List,
  edit: Edit,
  create: Create
};
