import createFilters from "./createFilters";
import createIdFilter from "./createIdFilter";
import makeid from "../utils/makeid";
import { HttpError } from "ra-core";
export default ({ inventoryDb, params }) => {
  const { perPage, page } = params.pagination;
  // const { field /*, order*/ } = params.sort;
  const filterSelectors = createFilters(params);
  if (Object.keys(filterSelectors).length === 0) {
    return Promise.resolve({ data: [], total: 0 });
  }
  const filterator = createIdFilter({ params });
  return inventoryDb.allDocs().then(({ rows, total_rows }) =>
    Promise.all(rows.filter(filterator).map(doc => inventoryDb.get(doc.id)))
      .then(data => ({
        data: data
          .filter((r, i) => i >= (page - 1) * perPage && i <= page * perPage)
          .map(record => ({
            ...record,
            id: record.id || makeid()
          })),
        total: data.length
      }))
      .catch(() => {
        throw new HttpError("app.sync.invalid_data");
      })
  );
};
