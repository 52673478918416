import { ImageField, ImageInput } from "react-admin";

import React from "react";
import { get } from "lodash";
import { grey } from "@material-ui/core/colors";
import { withStyles } from "@material-ui/core";

const ClickableImageField = ({ ...props }) => (
  <a href={get(props, "record.download_link")}>
    <ImageField source="download_link" title="filename" {...props} />
  </a>
);

const PicsInput = withStyles((theme) => ({
  root: {
    "& button": {
      marginTop: -theme.spacing.unit * 0.8,
      backgroundColor: grey[300],
      marginRight: -theme.spacing.unit * 0.5,
    },
    "& button svg": {
      fontSize: 30,
      color: grey[900],
    },
    "& button:hover": {
      backgroundColor: grey[300],
    },
    "& button:hover svg": {
      color: theme.palette.secondary.main,
    },
    "& img": {
      marginLeft: 0,
      marginTop: theme.spacing.unit * 2,
      marginRight: theme.spacing.unit * 3,
      borderRadius: theme.spacing.unit,
      borderWidth: theme.spacing.unit,
      borderColor: grey[300],
      borderStyle: "solid",
      cursor: "pointer",
    },
  },
}))(({ classes, ...props }) => (
  <ImageInput
    {...props}
    className={classes.root}
    accept="image/*"
    multiple={true}
  >
    <ClickableImageField source="download_link" title="filename" />
  </ImageInput>
));
export default PicsInput;
