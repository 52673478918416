import React from "react";
import {
  SimpleForm,
  TextInput,
  required,
  maxLength,
  email,
  ImageInput,
  ImageField,
  withTranslate,
} from "react-admin";
import { compose } from "recompose";
import Toolbar from "./Toolbar";

const styles = {
  input: {
    maxWidth: 300,
    display: "inline-block",
    float: "left",
    marginRight: 16,
  },
};

const Form = ({ translate, ...props }) => (
  <SimpleForm {...props} redirect="show" toolbar={<Toolbar />}>
    <TextInput
      fullWidth
      style={styles.input}
      source="device"
      validate={[required(), maxLength(100)]}
    />
    <TextInput
      fullWidth
      style={styles.input}
      source="brand"
      validate={[required(), maxLength(100)]}
    />
    <TextInput
      fullWidth
      style={styles.input}
      source="model"
      validate={[required(), maxLength(100)]}
    />
    <TextInput
      fullWidth
      style={styles.input}
      source="inventory_id"
      helperText={translate(
        "resources.maintenance-requests.fields.inventory_id_help"
      )}
      validate={[required(), maxLength(100)]}
    />
    <TextInput
      fullWidth
      style={styles.input}
      source="serial_number"
      validate={[required(), maxLength(100)]}
    />
    <TextInput source="notes" validate={[maxLength(255)]} fullWidth />
    <TextInput
      source="defect"
      validate={[required(), maxLength(1000)]}
      multiline
      fullWidth
    />
    <TextInput
      fullWidth
      style={styles.input}
      source="district"
      validate={[required(), maxLength(100)]}
    />
    <TextInput
      fullWidth
      style={styles.input}
      source="department"
      validate={[required(), maxLength(100)]}
    />
    <TextInput
      fullWidth
      style={styles.input}
      source="floor"
      validate={[required(), maxLength(100)]}
    />
    <TextInput
      fullWidth
      style={styles.input}
      source="room"
      validate={[required(), maxLength(100)]}
    />
    <TextInput
      fullWidth
      style={styles.input}
      source="access_times"
      validate={[required(), maxLength(100)]}
    />
    <TextInput
      fullWidth
      style={styles.input}
      source="referent"
      validate={[required(), maxLength(100)]}
    />
    <TextInput
      fullWidth
      style={styles.input}
      source="phone"
      validate={[required(), maxLength(30)]}
    />
    <TextInput
      fullWidth
      style={styles.input}
      source="email"
      validate={[required(), maxLength(100), email()]}
    />
    <ImageInput
      source="attachments"
      accept="image/*"
      multiple={true}
      validate={required()}
    >
      <ImageField source="filepath" title="filename" />
    </ImageInput>
  </SimpleForm>
);
export default compose(withTranslate)(Form);
