import React from "react";
// import Card from '@material-ui/core/Card';
// import CardContent from '@material-ui/core/CardContent';
// import Grid from '@material-ui/core/Grid';
import Typography from "@material-ui/core/Typography";
import { ShowController } from "react-admin";
import { createStyles, withStyles } from "@material-ui/core";
import { compose } from "recompose";
import { translate } from "ra-core";
import Table from "./table/EnhancedTable";
const styles = theme =>
  createStyles({
    table: {
      marginBottom: theme.spacing.unit * 2
    }
  });
const Show = ({ classes, translate, ...props }) =>
  props.id !== "0" ? (
    <ShowController {...props} title=" ">
      {({ record }) => <Table data={record.items} />}
    </ShowController>
  ) : (
    <Typography variant="body2">
      {translate("resources.progress.warn")}
    </Typography>
  );

export default compose(translate, withStyles(styles))(Show);
