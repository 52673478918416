import React, { Component } from "react";
import { Title, Responsive } from "react-admin";
import {
  Dialog,
  DialogTitle,
  DialogContentText,
  DialogContent,
  createStyles,
  withStyles,
  Snackbar,
  Button,
} from "@material-ui/core";
import { compose } from "recompose";
import { connect } from "react-redux";
import { translate, withDataProvider, Query } from "ra-core";
import LinearProgress from "@material-ui/core/LinearProgress";
import EditIcon from "@material-ui/icons/Edit";
import FlashOnIcon from "@material-ui/icons/FlashOn";
import DateUsageIcon from "@material-ui/icons/DataUsage";
import moment from "moment";

import {
  PRELOAD,
  GET_INFO,
  REFRESH,
  SYNCHRONIZE,
  CLEAR_PENDING_CHANGES,
} from "../addOfflineDataFeature";
import Indicator from "./Indicator";
import { red, orange, green } from "@material-ui/core/colors";
import SnackAlert from "../SnackAlert";
import isOffline from "../offline/isOffline";
import { GET_STATS } from "../addItemsFeature";
import PendingChanges from "./PendingChanges";
import { BUILD } from "../config";

const styles = (theme) =>
  createStyles({
    flex: {
      display: "flex",
      marginLeft: "1em",
      marginTop: "1em",
      [theme.breakpoints.down("sm")]: {
        clear: "both",
      },
    },
    flexColumn: {
      display: "flex",
      flexDirection: "column",
    },
    leftCol: { flex: 1, marginRight: "1em" },
    rightCol: { flex: 1, marginLeft: "1em" },
    singleCol: { margin: "1em" },
  });

const StatsIndicator = ({ version }) =>
  !isOffline() ? (
    <Query type={GET_STATS} key={version} resource="items">
      {({ data, loading }) => (
        <Indicator
          color={green[500]}
          icon={FlashOnIcon}
          value={
            loading
              ? "..."
              : `${data.assigned}/${data.total} (${(
                  (100 / data.total) *
                  data.assigned
                ).toFixed(2)}%)`
          }
          label="app.progress.name"
          help="app.progress.help"
        />
      )}
    </Query>
  ) : null;
const SynchChangesIndicator = ({ info, translate, loading, onClick }) => (
  <Indicator
    icon={EditIcon}
    value={info === null ? 0 : info.changes.length}
    color={
      info === null ? red[100] : info.changes.length > 0 ? red[500] : green[200]
    }
    label="app.sync.pendings"
    help={"app.sync.push.help"}
    button={
      <Button
        color="primary"
        variant="contained"
        onClick={onClick}
        disabled={
          isOffline() || (info !== null && info.changes.length === 0) || loading
        }
      >
        {translate("app.sync.push.name")}
      </Button>
    }
  />
);

const SynchDataIndicator = ({ info, translate, loading, onClick }) => (
  <Indicator
    icon={DateUsageIcon}
    value={
      info === null ? "" : moment(info.timestamp).format("DD/MM/YYYY HH:mm:ss")
    }
    color={orange[500]}
    label="app.sync.timestamp"
    help={"app.sync.refresh.help"}
    button={
      <Button
        color="primary"
        variant="contained"
        onClick={onClick}
        disabled={isOffline() || loading}
      >
        {translate("app.sync.refresh.name")}
      </Button>
    }
  />
);

class Dashboard extends Component {
  state = {
    percentage: 100,
    completed: false,
    message: null,
    info: null,
  };

  syncronizeData() {
    const { dataProvider } = this.props;
    if (!isOffline()) {
      dataProvider(PRELOAD, "items", {
        callback: ({ percentage, message }) => {
          this.setState({ percentage, message });
        },
      }).then(({ message }) =>
        dataProvider(GET_INFO, "items").then(({ data }) =>
          this.setState({ message, completed: true, info: data })
        )
      );
    } else {
      dataProvider(GET_INFO, "items").then(({ data }) =>
        this.setState({ info: data })
      );
    }
  }

  handleRefreshClick() {
    const { dataProvider } = this.props;

    dataProvider(REFRESH, "items", {
      callback: ({ percentage, message }) => {
        this.setState({ completed: false, percentage, message });
      },
    }).then(({ message }) =>
      dataProvider(GET_INFO, "items").then(({ data }) => {
        this.setState({ message, completed: true, info: data });
      })
    );
  }
  handleSynchronizeClick() {
    const { dataProvider } = this.props;
    dataProvider(SYNCHRONIZE, "items", {
      callback: ({ percentage, message }) => {
        this.setState({ completed: false, percentage, message });
      },
    }).then((output) => {
      this.syncronizeData();
      this.setState({ completed: true });
    });
  }

  handleClearPendingChangesClick() {
    const { dataProvider } = this.props;
    dataProvider(CLEAR_PENDING_CHANGES, "items").then((response) =>
      this.setState({
        info: {
          ...this.state.info,
          changes: [],
        },
      })
    );
  }

  componentDidMount() {
    this.syncronizeData();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.version !== this.props.version) {
      this.syncronizeData();
    }
  }

  render() {
    const { classes, translate, loading } = this.props;
    const { state } = this;
    const { info } = state;
    return (
      <div>
        <Title title={`Dashboard ${BUILD}`} />
        <Snackbar
          anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
          autoHideDuration={60000}
          open={isOffline() || (info !== null && info.changes.length > 0)}
        >
          <SnackAlert
            variant={"warning"}
            message={translate(
              info && info !== null && info.changes.length > 0
                ? "app.sync.warnings"
                : "app.offline"
            )}
          />
        </Snackbar>
        <Responsive
          small={
            <div>
              <div className={classes.flexColumn}>
                <div className={classes.flex}>
                  <StatsIndicator version={this.props.version} />
                </div>
                <div className={classes.flex}>
                  <PendingChanges
                    changes={(info && info.changes) || []}
                    translate={translate}
                    onClick={this.handleClearPendingChangesClick.bind(this)}
                  />
                </div>
                <div className={classes.flex}>
                  <SynchChangesIndicator
                    info={info}
                    translate={translate}
                    onClick={this.handleSynchronizeClick.bind(this)}
                  />
                </div>
                <div className={classes.flex}>
                  <SynchDataIndicator
                    info={info}
                    loading={loading}
                    translate={translate}
                    onClick={this.handleRefreshClick.bind(this)}
                  />
                </div>
              </div>
            </div>
          }
          medium={
            <div>
              <div className={classes.flexColumn}>
                <div className={classes.singleCol}>
                  <div className={classes.flex}>
                    <StatsIndicator version={this.props.version} />
                    <PendingChanges
                      changes={(info && info.changes) || []}
                      translate={translate}
                      button={
                        <Button
                          color="primary"
                          variant="contained"
                          onClick={this.handleClearPendingChangesClick.bind(
                            this
                          )}
                          disabled={loading}
                        >
                          {translate("app.sync.changes.clear")}
                        </Button>
                      }
                    />
                    <SynchChangesIndicator
                      info={info}
                      loading={loading}
                      translate={translate}
                      onClick={this.handleSynchronizeClick.bind(this)}
                    />
                    <SynchDataIndicator
                      info={info}
                      loading={loading}
                      translate={translate}
                      onClick={this.handleRefreshClick.bind(this)}
                    />
                  </div>
                </div>
              </div>
            </div>
          }
        />

        <Dialog
          open={
            !state.completed &&
            (state.percentage < 100 || state.message !== null)
          }
        >
          <DialogTitle>{translate("app.sync.title")}</DialogTitle>
          <DialogContent>
            <DialogContentText>
              {translate("app.sync.message")}
            </DialogContentText>
            <br />
            <LinearProgress
              variant={state.progress ? "buffer" : "indeterminate"}
              valueBuffer={100}
              value={state.percentage}
            />
            <DialogContentText>{state.message}</DialogContentText>
          </DialogContent>
        </Dialog>
      </div>
    );
  }
}
export default compose(
  connect((state) => ({
    version: state.admin.ui.viewVersion,
    loading: state.admin.loading > 0,
  })),
  withDataProvider,
  translate,
  withStyles(styles)
)(Dashboard);
