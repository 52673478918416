import idSchema from "./idSchema";
export default ({ params }) => {
  let keys = Object.keys(params.filter);
  let matchers = keys.map(key => (doc, index) => {
    if (doc.id.indexOf("_design/") !== -1) {
      return false;
    }
    let match = `(${idSchema[key]}:)*.${params.filter[key]}.(|)`;
    switch (key) {
      case "keyword":
        match = `${params.filter[key]}`;
        return doc.id.indexOf(match) !== -1;
      case "inventory_id":
      case "new_inventory_id":
      case "previous_inventory_id":
      case "previous_inventory_id_2":
      case "id_number":
        match = `${idSchema[key]}:${params.filter[key]}|`;
        return doc.id.indexOf(match) !== -1;
      case "has_new_inventory_id":
        match = `${idSchema.new_inventory_id}:|`;
        const isNewInventoryEmpty = doc.id.indexOf(match) !== -1;
        return isNewInventoryEmpty !== params.filter.has_new_inventory_id;
      default:
        break;
    }
    return doc.id.match(match) !== null;
  });
  const length = matchers.length;
  return (doc, index) =>
    matchers.filter(matcherFn => matcherFn(doc, index)).length === length;
};
