import { createStyles, withStyles } from "@material-ui/core";

import AppBar from "./AppBar";
import CustomLayout from "./CustomLayout";
import React from "react";
import { Sidebar } from "react-admin";

const styles = createStyles((theme) => ({
  sidebar: {
    [theme.breakpoints.up("md")]: {
      height: "100%",
      marginTop: -70,
      marginRight: 16,
      marginBottom: -128,
    },
  },
}));
const MySidebar = withStyles(styles)(({ classes, ...props }) => (
  <Sidebar {...props} className={classes.sidebar} size={250} />
));
const Layout = (props) => (
  <CustomLayout appBar={AppBar} {...props} sidebar={MySidebar} />
);

export default Layout;
