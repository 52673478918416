import React from "react";
import {
  Create,
  SimpleForm,
  TextInput,
  SelectInput,
  BooleanInput,
} from "react-admin";

const UserCreate = (props) => (
  <Create {...props}>
    <SimpleForm redirect="list">
      <BooleanInput source="is_active" />
      <TextInput source="username" />
      <TextInput source="password" type="password" />
      <TextInput source="email" type="email" />
      <SelectInput
        source="role"
        choices={[
          {
            id: "admin",
            name: "admin",
          },
          {
            id: "user",
            name: "user",
          },
          {
            id: "reader",
            name: "reader",
          },
        ]}
      />
    </SimpleForm>
  </Create>
);

export default UserCreate;
